/* eslint-disable @typescript-eslint/no-explicit-any */
// axios
import axios from "axios"
// API_URL
import {API_URL} from "../constants"

export async function getAssociates() {
   const res = await axios({
      method: "GET",
      url: `${API_URL}/asociados/`,
      withCredentials: true,
      headers: {
         "Content-Type": "application/json",
      },
   })
   return res.data.associates
}

export async function postAssociate(associate: any) {
   await axios({
      method: "post",
      url: `${API_URL}/asociados`,
      withCredentials: true,
      headers: {
         "Content-Type": "application/json",
      },
      data: associate,
   })
}

export async function editAssociate(id: string, associate: any) {
   await axios({
      method: "put",
      url: `${API_URL}/asociados/${id}`,
      withCredentials: true,
      headers: {
         "Content-Type": "application/json",
      },
      data: associate,
   })
}
