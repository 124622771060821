import {TextField} from "@mui/material"
import React, {useEffect} from "react"
import Calendar from "react-calendar"
import "react-calendar/dist/Calendar.css"
import {Button, Alert} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import AddIcon from "@mui/icons-material/Add"
import {editDate,putDate} from "../../api/inmueble"
import "./Calendar.css"
import InputLabel from "@mui/material/InputLabel"
import NativeSelect from "@mui/material/NativeSelect"
import {clearCache} from "@/utils"

interface CalendarFormProps {
   hideForm?: () => void
   inmuebleId: string
   fetchInmueble: () => void
   calendarPast: any
   currDate?: any
   currNote?: string
   apiDate?: any
}

type RentedByType = "CAD" | "Propietario"

function CalendarForm({hideForm,inmuebleId, fetchInmueble, calendarPast, currDate = null, currNote, apiDate=null}: CalendarFormProps) {
   const [dateRange, setDateRange] = React.useState<any>(currDate)
   const [notes, setNotes] = React.useState(currNote)
   const [rentedBy, setRentedBy] = React.useState<RentedByType>("CAD")
   const [error, setError] = React.useState<string | boolean>(false)

   // Al cambiar currDate, también se debe actualizar el dateRange
   useEffect(() => {
      if (currDate) {
      setDateRange(currDate); // Cargar rango actual en modo edición
      }
   }, [currDate]);

   const isWithinRanges = (date: Date, ranges: any = []) => {
      return ranges.some((range: any) => {
         const start = new Date(range.start._seconds * 1000)
         const end = new Date(range.end._seconds * 1000)
         return date >= start && date <= end
      })
   }

   const tileDisabled = ({date, view}: any) => {
      if (view === "month") {
        const currentDate = new Date(date.setHours(0, 0, 0, 0));
        if (currDate) {
          const [start, end] = currDate;
          const editStart = new Date(start.setHours(0, 0, 0, 0));
          const editEnd = new Date(end.setHours(0, 0, 0, 0));
          if (currentDate >= editStart && currentDate <= editEnd) {
            return false;
          }
        }
        return isWithinRanges(currentDate, calendarPast);
      }
      return false;
    };

   const isRangeOverlapping = (start: Date, end: Date, ranges = []) => {
      return ranges.some((range: any) => {
         const rangeStart = new Date(range.start._seconds * 1000);
         const rangeEnd = new Date(range.end._seconds * 1000);
         if (currDate) {
            const [editStart, editEnd] = currDate;
            const editStartDate = new Date(editStart.setHours(0, 0, 0, 0));
            const editEndDate = new Date(editEnd.setHours(0, 0, 0, 0));

         
         const isEditRange = (start
                              <= editEndDate && end
                              >= editStartDate)
         if (isEditRange) {
            return false;
         }
            
         }
         return (
            (start >= rangeStart && start <= rangeEnd) ||
            (end >= rangeStart && end <= rangeEnd) ||
            (start <= rangeStart && end >= rangeEnd)
         );
      });
   };

     // Resaltar la fecha actual en modo edición
  const tileClassName = ({ date, view }: any) => {
   if (view === "month" && currDate) {
     const [start, end] = currDate;

     if (date >= start && date <= end) {
       return "highlight-green"; // Clase CSS personalizada para resaltar en verde
     }
   }
   return "";
 };

   const handleSubmit = async () => {
      try {
         setError(false)
         if (!dateRange || dateRange.length < 2) {
            setError("Selecciona un rango de fechas")
            return
         }

         const [start, end] = dateRange

         if (isRangeOverlapping(start, end, calendarPast)) {
            setError("El rango seleccionado incluye fechas que ya están reservadas")
            return
         }

         const calendar = {
            start: start,
            end: end,
            notes,
            rentedBy,
         }

         if (apiDate) {
            // Edición de una fecha existente
            await editDate(inmuebleId, calendar, calendarPast, apiDate)
            hideForm && hideForm()
          } else {
            // Agregación de una nueva fecha
            await putDate(inmuebleId, calendar, calendarPast)
          }
    
         fetchInmueble()
         clearCache()
      } catch (error) {
         console.log(error)
         setError("Error al crear la fecha")
      }
   }

   return (
      <div className="rounded shadow-md">
         <h4 className="my-3 font-bold text-lg text-blue-600">
            {apiDate ? "Editar fecha" : "Añadir fecha"}
         </h4>
         {error && (
            <Alert severity="error" className="w-[27%] mb-4 mx-auto">
               {error}
            </Alert>
         )}
         <Calendar
            onChange={setDateRange}
            value={dateRange}
            selectRange={true}
            className="mx-auto mb-3"
            tileDisabled={tileDisabled}
            tileClassName={tileClassName}
         />

         <div className="mx-auto w-full md:w-[50%] h-full my-6">
            <InputLabel variant="standard" className="text-start mb-2 ml-4">
               Ocupación Propietario / CAD
            </InputLabel>
            <NativeSelect
               className="w-full md:w-[80%] p-2"
               defaultValue={"CAD"}
               onChange={(e) => setRentedBy(e.target.value as RentedByType)}
               value={rentedBy}
            >
               <option value="CAD">CAD</option>
               <option value="Propietario">Propietario</option>
            </NativeSelect>
         </div>
         <TextField
            label={"Notas"}
            id="margin-none"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            className="w-[27%] mb-4"
         />
         <div className="flex flex-row justify-center space-x-6 flex-wrap my-4">
         {apiDate && (
          <Button
            variant="contained"
            style={{
              backgroundColor: "#dc2626",
              color: "white",
              marginBottom: "10px",
            }}
            startIcon={<DeleteIcon />}
            onClick={hideForm}
          >
            Cancelar
          </Button>
        )}
        <Button
          variant="contained"
          style={{
            backgroundColor: dateRange ? "#2563eb" : "#9ca3af",
            color: "white",
            marginBottom: "10px",
          }}
          startIcon={<AddIcon />}
          onClick={handleSubmit}
          disabled={!dateRange}
        >
          {apiDate ? "Editar" : "Agregar"}
        </Button>
         </div>
      </div>
   )
}

export default CalendarForm
