import React from "react"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogActions from "@mui/material/DialogActions"

interface ConfirmationDialogProps {
   open: boolean
   message: string
   onConfirm: () => void
   onCancel: () => void
}

/**
 * Confirmation dialog initially developed for the category delete button.
 */
export default function ConfirmationDialog({
   open,
   message,
   onConfirm,
   onCancel,
}: ConfirmationDialogProps) {
   return (
      <Dialog open={open} onClose={onCancel}>
         <DialogContent>
            <DialogContentText>{message}</DialogContentText>
         </DialogContent>
         <DialogActions>
            <button onClick={onCancel} className="text-white bg-gray-500 px-4 py-1 rounded">
               Cancelar
            </button>
            <button onClick={onConfirm} className="text-white bg-red px-4 py-1 rounded">
               Eliminar
            </button>
         </DialogActions>
      </Dialog>
   )
}
