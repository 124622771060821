// Text input component, with icon
// Rodrigo Nunez, May 2024
import React from "react"

/**
 * Text input component, with icon
 * @param icon Icon to display
 * @param placeholder Placeholder text
 * @param type Input type
 * @param value Input value
 * @param onChange On change event
 * @param required Required field
 * @param disabled Disabled field
 * @returns
 */
export default function TextInput({
   icon,
   placeholder,
   type,
   value,
   onChange,
   required = false,
   disabled = false,
}: {
   icon?: React.ReactNode
   placeholder: string
   type: string
   value: string | number
   required?: boolean
   disabled?: boolean
   onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}) {
   return (
      <div className="flex items-center border border-gray-400 rounded-lg p-[6px] px-4 w-[70vw] md:w-[300px] bg-white">
         {icon}
         <input
            type={type}
            placeholder={placeholder}
            value={value}
            required={required}
            disabled={disabled}
            onChange={onChange}
            className="px-2 py-1 rounded-md w-full focus:outline-none bg-inherit"
         />
      </div>
   )
}
