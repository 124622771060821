import React from "react"
import {Alert} from "@mui/material"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import axios from "axios"
import {API_URL} from "@/constants"
import {DateValue, RangeCalendar} from "@nextui-org/calendar"
import {NextUIProvider} from "@nextui-org/system"
import {today, getLocalTimeZone} from "@internationalized/date"

interface Props {
   hideForm: () => void
   fetchTemporadas: () => Promise<void>
}

function TemporadasForm({hideForm, fetchTemporadas}: Props) {
   const [dateRange, setDateRange] = React.useState<any>()
   const [isHigh, setIsHigh] = React.useState("")
   const [error, setError] = React.useState<string | boolean>(false)

   const handleDateChange = (date: {start: DateValue; end: DateValue}) => {
      const {start, end} = date
      // convert values to Date obj
      const startDate = start.toDate(getLocalTimeZone())
      const endDate = end.toDate(getLocalTimeZone())
      setDateRange([startDate, endDate])
   }

   const handleSubmit = async () => {
      try {
         setError(false)
         if (!dateRange) {
            setError("Please select a date range")
            return
         }
         if (dateRange.length < 2) {
            setError("Please select a date range")
            return
         }
         if (!isHigh || isHigh === "") {
            setError("Seleccione una temporada")
            return
         }
         const newTemp = {
            initial: dateRange[0],
            end: dateRange[1],
            is_high: isHigh === "true" ? true : false,
         }
         await axios({
            method: "post",
            url: `${API_URL}/temporadas/temporada`,
            data: newTemp,
            withCredentials: true,
            headers: {
               "Content-Type": "application/json",
            },
         })
         fetchTemporadas()
         hideForm()
      } catch (error) {
         console.log(error)
         setError("Error al crear la fecha")
      }
   }

   return (
      <div className="my-2 mx-auto flex flex-col items-center gap-4">
         <h4 className="my-3 font-bold text-3xl text-blue text-center">Añadir Temporada</h4>
         {error && (
            <Alert severity="error" className=" mb-4 mx-auto">
               {error}
            </Alert>
         )}
         <NextUIProvider>
            <RangeCalendar
               onChange={handleDateChange}
               className="mx-auto mb-3"
               color="success"
               minValue={today(getLocalTimeZone())}
            />
         </NextUIProvider>
         <FormControl className="w-[22rem] mb-4">
            <InputLabel id="demo-controlled-open-select-label">Temporada alta o media</InputLabel>
            <Select
               displayEmpty
               labelId="demo-controlled-open-select-label"
               id="demo-controlled-open-select"
               label="Temporada alta o media"
               value={isHigh}
               onChange={(e) => setIsHigh(e.target.value)}
            >
               <MenuItem value={"true"}>Alta</MenuItem>
               <MenuItem value={"false"}>Media</MenuItem>
            </Select>
         </FormControl>
         <div className="flex flex-col items-center gap-4 my-4">
            <button
               disabled={!dateRange}
               onClick={handleSubmit}
               className="bg-green hover:bg-gray-800 text-white px-6 py-1 rounded-lg shadow-lg"
            >
               Agregar temporada
            </button>
            <button
               onClick={hideForm}
               className="bg-gray-500 hover:bg-gray-800  text-white px-6 py-1 rounded-lg shadow-lg"
            >
               Cancelar
            </button>
         </div>
      </div>
   )
}

export default TemporadasForm
