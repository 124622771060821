// Users page
// Rodrigo Nunez, May 2024
import React from "react"
import Page from "@/layouts/Page"
import {Link} from "react-router-dom"
import AddIcon from "@mui/icons-material/Add"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import {useAuthContext} from "@/hooks/useAuthContext"

export default function Users() {
   const {user} = useAuthContext()

   const data = {
      name: "Rodrigo Prueba",
      role: "Admin",
      email: "roprueba@siempre.com",
      phone: "+52 5555555555",
   }

   return (
      <Page className="flex flex-col items-center py-12 gap-8">
         <h1 className="text-blue text-3xl font-bold">
            Usuarios Activos en Casas Acapulco Diamante
         </h1>
         {user.admin && (
            <Link
               to="/usuarios/agregar"
               className="py-2 px-6 rounded-full bg-blue text-white flex items-center gap-2"
            >
               <AddIcon />
               Agregar Usuario
            </Link>
         )}
         <section className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 items-center justify-center gap-6">
            {Array.from({length: 10}).map((_, i) => (
               <div
                  key={i}
                  className="p-8 bg-[#F0F0F0] border border-gray-300 rounded-lg shadow-xl relative max-w-[300px]"
               >
                  <button
                     className="absolute top-2 right-2"
                     onClick={() => alert("Funcionalidad pendiente de desarrollar!")}
                  >
                     <EditOutlinedIcon />
                  </button>
                  <h3 className="font-bold text-lg">{data.name}</h3>
                  <hr className="w-full h-[2px] mt-2 mb-4 bg-gray-500" />
                  <p className="font-bold text-green text-lg">Rol: {data.role}</p>
                  <p>Email: {data.email}</p>
                  <p>Tel: {data.phone}</p>
               </div>
            ))}
         </section>
      </Page>
   )
}
