import React from "react"

type BooleanToggleProps = {
   label: string
   onPress: () => void
   condition: boolean
}

/**
 * Toggle button for boolean properties, originally for the PropertyPage Component.
 * @param label - The label of the toggle button.
 * @param onPress - The function to call when the toggle button is pressed.
 * @param condition - The boolean value to determine the state of the toggle button.
 * @returns A toggle button for boolean properties.
 */
export default function BooleanToggle({label, onPress, condition}: BooleanToggleProps) {
   return (
      <button
         className={`border rounded md:w-[30%] xl:w-[20%] ${
            condition
               ? "bg-green border border-green text-green "
               : "bg-red border border-red text-red"
         } px-4 py-1 text-center bg-opacity-20`}
         onClick={onPress}
      >
         {label}
      </button>
   )
}
