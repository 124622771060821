/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useEffect} from "react"
// update password
import {changePassword} from "../../api/usuario"
// styles
import {inmAndCondStyles} from "../../constants"
// auth context
import {useAuthContext} from "../../hooks/useAuthContext"
import {updateUser} from "../../api/usuario"
// TextField & material UI
import {Button} from "@mui/material"
import InputAdornment from "@mui/material/InputAdornment"
import PersonIcon from "@mui/icons-material/Person"
import PhoneIcon from "@mui/icons-material/Phone"
import {TextField} from "@mui/material"
import {Alert} from "@mui/material"
import LockIcon from "@mui/icons-material/Lock"

interface Props {
   hideForm: () => void
   name: string
   phone: any
   fetchUser: () => Promise<void>
}

function EditProfile({hideForm, name, phone, fetchUser}: Props) {
   // state
   const [isPending, setIsPending] = useState(false)
   const {user} = useAuthContext()
   const [error, setError] = useState<string | boolean>(false)
   const [newName, setNewName] = useState(name)
   const [newPhone, setNewPhone] = useState(phone)
   const [password, setPassword] = useState("")
   const [confirmedPassword, setConfirmedPassword] = useState("")

   const handleSubmit = async () => {
      setIsPending(true)
      setError(false)
      if (name === "") {
         setError("El nombre no puede estar vacío")
         setIsPending(false)
         return
      }
      if (password !== confirmedPassword) {
         setError("Las contraseñas no coinciden")
         setIsPending(false)
         return
      }
      try {
         if (password === "") {
            // update user
            await updateUser(user?.email, newName, newPhone)
         } else {
            if (newName !== name || newPhone !== phone) {
               await changePassword(password)
               // update user
               await updateUser(user?.email, newName, newPhone)
            } else {
               await changePassword(password)
            }
         }
         await fetchUser()
         setIsPending(false)
         hideForm()
      } catch (error) {
         console.log(error.message)
         if (
            error.message ===
            "Firebase: Password should be at least 6 characters (auth/weak-password)."
         )
            setError("La contraseña debe tener al menos 6 caracteres")
         else
            setError(
               "Necesitas de haber iniciado sesión recientemiente para actualizar tu contraseña, cierra sesión y vuelve a intentar",
            )
         setIsPending(false)
      }
   }

   useEffect(() => {
      setPassword("")
      setConfirmedPassword("")
   }, [])

   return (
      <div className={inmAndCondStyles.secondaryDiv}>
         <h3 className="text-2xl font-bold text-blue-600 mb-5">Editar Datos</h3>
         <div className="border rounded shadow-lg px-8 pt-6 pb-8 mb-4 flex flex-col items-center w-[60%]">
            {/* nombre */}
            {error && (
               <Alert className="mb-7" severity="error">
                  {error}
               </Alert>
            )}
            <TextField
               className="w-[40%]"
               onChange={(e) => setNewName(e.target.value)}
               value={newName}
               id="nombre"
               label="Nombre"
               InputProps={{
                  className: "mb-7",
                  startAdornment: (
                     <InputAdornment position="start">
                        <PersonIcon />
                     </InputAdornment>
                  ),
               }}
               variant="standard"
            />
            {/* telefono */}
            <TextField
               className="w-[40%]"
               onChange={(e) => setNewPhone(e.target.value)}
               value={newPhone}
               id="telefono"
               label="Teléfono (opcional)"
               InputProps={{
                  className: "mb-7",
                  startAdornment: (
                     <InputAdornment position="start">
                        <PhoneIcon />
                     </InputAdornment>
                  ),
               }}
               variant="standard"
            />
            {/* password */}
            <TextField
               className="w-[40%]"
               onChange={(e) => setPassword(e.target.value)}
               value={password}
               id="password"
               type="password"
               label="Contraseña (opcional)"
               InputProps={{
                  className: "mb-7",
                  startAdornment: (
                     <InputAdornment position="start">
                        <LockIcon />
                     </InputAdornment>
                  ),
               }}
               variant="standard"
            />
            {/* confirm password */}
            <TextField
               className="w-[40%]"
               onChange={(e) => setConfirmedPassword(e.target.value)}
               value={confirmedPassword}
               id="confirmedPassword"
               type="password"
               label="Confirmar contraseña (opcional)"
               InputProps={{
                  className: "mb-7",
                  startAdornment: (
                     <InputAdornment position="start">
                        <LockIcon />
                     </InputAdornment>
                  ),
               }}
               variant="standard"
            />
            <div className="felx space-x-4">
               <Button
                  style={{
                     backgroundColor: "#dc2626",
                     color: "white",
                     marginBottom: "10px",
                  }}
                  variant="contained"
                  onClick={hideForm}
                  disabled={isPending}
               >
                  Cancelar
               </Button>
               {!isPending && (
                  <Button
                     variant="contained"
                     style={{
                        backgroundColor: `${
                           name === newName && phone === newPhone && password === ""
                              ? "#6b7280"
                              : "#2563eb"
                        }`,
                        color: "white",
                        marginBottom: "10px",
                     }}
                     disabled={name === newName && phone === newPhone && password === ""}
                     onClick={() => handleSubmit()}
                  >
                     Guardar cambios
                  </Button>
               )}
               {isPending && (
                  <Button
                     variant="contained"
                     style={{
                        backgroundColor: "#2563eb",
                        color: "white",
                        marginBottom: "10px",
                     }}
                     disabled
                  >
                     Cargando...
                  </Button>
               )}
            </div>
         </div>
      </div>
   )
}

export default EditProfile
