import React from "react"

interface StatusIndicatorProps {
   label: string
   condition: boolean
}

export default function StatusIndicator({label, condition}: StatusIndicatorProps) {
   return (
      <div
         className={`border rounded  ${
            condition
               ? "bg-green border border-green text-green "
               : "bg-red border border-red text-red"
         } px-4 py-1 text-center bg-opacity-20`}
      >
         {label}
      </div>
   )
}
