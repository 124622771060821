import {useEffect, useState} from "react"
// context custom hook
import {useAuthContext} from "./useAuthContext"
import {API_URL} from "../constants"

export const useLogout = () => {
   // get the dispatch function of the auth context,
   // this will let us change the state of the reducer
   const {dispatch} = useAuthContext()
   // state for different values
   const [error, setError] = useState<null | string>(null)
   const [isPending, setIsPending] = useState(false)
   const [isCancelled, setIsCancelled] = useState(false)

   // function called when the button of the navabr is clicked
   const logout = async () => {
      setError(null)
      setIsPending(true)

      // sign the user out
      try {
         await fetch(`${API_URL}/usuarios/logout`, {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
            },
            credentials: "include",
         })
         // dispatch logout action
         dispatch({type: "LOGOUT"})

         if (!isCancelled) {
            //update states
            setIsPending(false)
            setError(null)
         }
      } catch (err) {
         if (!isCancelled) {
            setError("Could not logout")
            setIsPending(false)
         }
      }
   }

   // if it gets cancellled, it stops the execution
   useEffect(() => {
      setIsCancelled(false)
      return () => setIsCancelled(true)
   }, [])

   return {logout, isPending, error}
}
