import React, {useState, useEffect} from "react"
import CondominioCard from "@/components/condos/CondoCard"
import {AxiosResponse} from "axios"
import {inmAndCondStyles} from "@/constants"
import ListAltIcon from "@mui/icons-material/ListAlt"
import {getCondominios} from "@/api/condominio"
import AddIcon from "@mui/icons-material/Add"
import Page from "@/layouts/Page"
import {Link} from "react-router-dom"
import CloseIcon from "@mui/icons-material/Close"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import {useAuthContext} from "@/hooks/useAuthContext"

function Condominios() {
   const [condominios, setCondominios] = useState<any[]>([])
   const [lastCond, setLastCond] = useState<null | string>(null)
   const [loading, setLoading] = useState<boolean>(false)
   const [noMoreCond, setNoMoreCond] = useState(false)
   const [searchString, setSearchString] = useState("")
   const [searchPage, setSearchPage] = useState(0)
   const {user} = useAuthContext()

   // Local storage logic to preserve some state
   const storedSearchQuery = localStorage.getItem("condosQuery") || ""
   const setStoredSearchQuery = (val: string) => localStorage.setItem("condosQuery", val)

   const fetchedCondos = JSON.parse(localStorage.getItem("condos") || "[]")
   const setFetchedCondos = (val: any[]) => localStorage.setItem("condos", JSON.stringify(val))

   // run this when the component mounts
   useEffect(() => {
      setNoMoreCond(false)
      const fetchCondos = async () => {
         setLoading(true)
         // fetch first condos
         if (storedSearchQuery) setSearchString(storedSearchQuery)
         if (fetchedCondos?.length > 0) {
            setLoading(false)
            setCondominios(fetchedCondos)
            return
         }
         const res = await getCondominios(
            null,
            storedSearchQuery ? storedSearchQuery : searchString,
            searchPage,
         )
         await changeState(res)
      }
      fetchCondos()
   }, [])

   // helper functions to change state and condominios
   const changeState = async (res: AxiosResponse<any, any>) => {
      // if nothing is found, return without changing any state
      if (res.data.data.length === 0) {
         setLoading(false)
         setCondominios([])
         setFetchedCondos([])
         setNoMoreCond(true)
         return
      }
      // set condominios to the data we get from node
      setCondominios(res.data.data)
      setFetchedCondos(res.data.data)
      // asign the last condominio to the last element of our response
      setLastCond(res.data.data[res.data.data.length - 1].name)
      setLoading(false)
   }

   const changeCondominios = async (res: AxiosResponse<any, any>) => {
      // when more data is fetched, we just concatenate the previous condominios
      // with the new ones
      if (res.data.data.length === 0) {
         setNoMoreCond(true)
         return
      }
      setCondominios([...condominios, ...res.data.data])
      setFetchedCondos([...condominios, ...res.data.data])
      // asign the last condominio to the last element of our response
      setLastCond(res.data.data[res.data.data.length - 1].name)
      setLoading(false)
   }

   const handleSearchStringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchString(e.target.value)
      setStoredSearchQuery(e.target.value)
      if (e.target.value === "") {
         setSearchPage(0)
         setNoMoreCond(false)
         setCondominios([])
         setFetchedCondos([])
         setLoading(true)
         const fetchCondos = async () => {
            // fetch first condos
            const res = await getCondominios(null, "", 0)
            await changeState(res)
         }
         fetchCondos()
      }
   }

   const handleSearch = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      setLoading(true)
      setNoMoreCond(false)
      const res = await getCondominios(null, searchString, searchPage)
      await changeState(res)
   }

   const fetchMore = async () => {
      const res = await getCondominios(lastCond, searchString, searchPage + 1)
      if (searchString) {
         setSearchPage(searchPage + 1)
      }
      await changeCondominios(res)
   }

   const clearSearchString = () => {
      setSearchString("")
      setStoredSearchQuery("")
      setSearchPage(0)
      setNoMoreCond(false)
      setCondominios([])
      setFetchedCondos([])
      setLoading(true)
      const fetchCondos = async () => {
         // fetch first condos
         const res = await getCondominios(null, "", 0)
         await changeState(res)
      }
      fetchCondos()
   }

   return (
      <Page className="flex flex-col items-center my-8 gap-4 p-4 justify-center">
         <h1 className="text-2xl md:text-3xl font-bold text-blue text-center">
            Buscador de Condominios Socios
         </h1>
         <section className="flex flex-col md:flex-row gap-4 md:gap-8">
            {user.admin && (
               <>
                  <Link
                     to="/condominios/nuevo"
                     className="flex py-2 px-4 gap-2 rounded-full bg-blue text-white"
                  >
                     <AddIcon />
                     Agregar Condominio
                  </Link>
                  <Link
                     to="/condominios/excelView"
                     className="flex py-2 px-4 gap-2 rounded-full bg-blue text-white"
                  >
                     <ListAltIcon />
                     Ver modo Excel
                  </Link>
               </>
            )}
         </section>
         <form
            action="submit"
            onSubmit={handleSearch}
            className="flex flex-col items-center content-center gap-4"
         >
            <div className="flex border border-blue rounded-md w-full px-2 py-1 items-center">
               <input
                  type="text"
                  name="Search String"
                  id="search-string"
                  value={searchString}
                  onChange={handleSearchStringChange}
                  className="px-2 py-1 focus:outline-none"
                  placeholder="Buscar por nombre"
               />
               <button onClick={clearSearchString} type="button">
                  <CloseIcon />
               </button>
            </div>
            <button className="border rounded-[10px] bg-blue text-white px-2 py-1" type="submit">
               Buscar
            </button>
         </form>
         {loading ? (
            <h2 className={inmAndCondStyles.loading}>Cargando...</h2>
         ) : (
            <section className="flex flex-col gap-4 w-full items-center">
               {condominios.map((condominio) => (
                  <CondominioCard key={condominio.id} condo={condominio} />
               ))}
            </section>
         )}
         <section className="my-8 flex flex-col gap-4 ">
            {!noMoreCond && condominios.length % 20 === 0 && (
               <button
                  onClick={fetchMore}
                  className="border rounded-[10px] bg-green text-white px-2 py-1 mx-auto"
               >
                  Cargar más
               </button>
            )}
            <button
               onClick={() => window.scrollTo({top: 0, behavior: "smooth"})}
               className="border rounded-[10px] bg-green text-white px-2 py-1 flex items-center gap-2 mx-auto"
            >
               Volver arriba
               <ArrowUpwardIcon />
            </button>
         </section>
      </Page>
   )
}

export default Condominios
