import React, {useEffect, useState} from "react"
import {useLocation, useNavigate, useParams} from "react-router-dom"
import CalendarForm from "@/components/calendar/CalendarForm"
import EditCalendarForm from "@/components/calendar/EditCalendarForm"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import EditRoundedIcon from "@mui/icons-material/EditRounded"
import CameraAltIcon from "@mui/icons-material/CameraAlt"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import CondominioInfo from "@/components/condos/CondoInfo"
import {YouTubeEmbed, TikTokEmbed} from "react-social-media-embed"
import ImagesColumn from "@/components/carrousel/ImagesColumn"
import ShareBtn from "@/components/properties/ShareBtn"
import {inmAndCondIndStyles as styles} from "@/constants"
import {getInmuebleById} from "@/api/inmueble"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import copy from "copy-to-clipboard"
import dayjs from "dayjs"
import {deleteDate as deleteDateApi} from "@/api/inmueble"
import {REDIRECT_URL} from "@/constants"
import Page from "@/layouts/Page"
import {clearCache, getTypeForURL, standarizeName} from "@/utils"
import EditButton from "@/components/properties/EditButton"
import {PROPERTY_CHARACTERISTICS, PROPERTY_SECTIONS} from "@/components/properties/ConstantData"
import PageSection from "@/layouts/PageSection"
import {useAuthContext} from "@/hooks/useAuthContext"
import StatusIndicator from "@/components/properties/StatusIndicator"
import StatusTile from "@/components/properties/StatusTile"

function PropertyPage() {
   // get the values sent from the useNavigate()
   const location = useLocation()
   const params = useParams()
   const navigate = useNavigate()
   // get the id of the inmueble
   // se divide el url por sus slashes y se agarra la segunda posicion, que es el id
   const id = location.pathname.split("/")[2]
   // state
   const [property, setProperty] = useState<any>()
   const [scheme, setScheme] = useState<string>("")
   // const show calendar form
   const [showEditCalendarForm, setShowEditCalendarForm] = useState(false)
   const [disableDelete, setDisableDelete] = useState(false)
   const [currDate, setCurrDate] = useState<any>(null)
   const [currNote, setCurrNote] = useState<any>(null)
   const [date, setDate] = useState<any>(null)
   const [displayCopied, setDisplayCopied] = useState(false)
   const {user} = useAuthContext()

   // hide calendar form
   const hideEditCalendarForm = () => {
      setShowEditCalendarForm(false)
   }

   useEffect(() => {
      const state: any = location.state
      const getInm = async () => {
         try {
            // if there is no prop, fetch data again
            if (!state) {
               const res = await getInmuebleById(id)
               setProperty(res.data.data)
            }
            // if the prop is defined, then just set inmuebles and img to the value sent
            if (state) {
               setProperty(state.inmueble)
            }
         } catch (error) {
            console.log("Error de inmueble: " + error)
         }
      }
      getInm()
   }, [params.id])

   useEffect(() => {
      if (location.pathname.includes("renta")) {
         setScheme("rent")
      } else if (location.pathname.includes("venta")) {
         setScheme("sale")
      } else {
         setScheme("")
      }
      window.scrollTo(0, 0)
   }, [location.pathname])

   // Invalidate property cache when a new date is added, and fetch the new data
   const invalidateCache = async () => {
      try {
         clearCache()
         const res = await getInmuebleById(id)
         setProperty(res.data.data)
         setDisableDelete(false)
         window.history.replaceState({}, "")
      } catch (error) {
         console.log("Error de inmueble: " + error)
      }
   }

   const handleEdit = (date: any) => {
      // timestamp to date
      const start = new Date((date.start._seconds + date.start._nanoseconds * 10 ** -9) * 1000)
      const end = new Date((date.end._seconds + date.end._nanoseconds * 10 ** -9) * 1000)
      setDate(date)
      setCurrDate([start, end])
      setCurrNote(date.notes)
      setShowEditCalendarForm(true)
   }

   const deleteDate = async (index: number) => {
      setDisableDelete(true)
      try {
         const eliminatedDate = property.calendar[index]
         await deleteDateApi(id, eliminatedDate, property.calendar)
         invalidateCache()
      } catch (error) {
         console.log("Error al borrar fecha: " + error)
         setDisableDelete(false)
      }
   }

   const copyToClipboard = async () => {
      setDisplayCopied(true)
      copy(
         `${REDIRECT_URL}/${property.forSale ? "venta" : "renta"}/${getTypeForURL(
            property.type,
         )}/${standarizeName(property.name)}/?id=${id}`,
         {
            debug: true,
            message: "Hello",
         },
      )
      setTimeout(() => {
         setDisplayCopied(false)
      }, 2000)
   }

   const SchemeBtn = ({sale = true}: {sale?: boolean}) => {
      const selected = (sale && scheme === "sale") || (!sale && scheme === "rent")
      const targetPath = sale ? "venta" : "renta"
      const targetScheme = sale ? "sale" : "rent"
      return (
         <button
            className={`border-2 border-blue ${
               selected ? "text-white bg-blue" : "text-blue"
            } rounded px-8`}
            onClick={
               selected
                  ? () => {
                       setScheme("")
                       navigate(`/inmuebles/${id}`)
                    }
                  : () => {
                       setScheme(targetScheme)
                       navigate(`/inmuebles/${id}/${targetPath}`)
                    }
            }
         >
            {sale ? "Venta" : "Renta"}
         </button>
      )
   }

   const EnvironmentP = ({env, key}: {env: string; key: any}) => (
      <p key={key} className="px-2 py-1 border border-blue text-blue rounded">
         {env}
      </p>
   )

   if (property) {
      return (
         <Page className="md:p-8 max-w-[1600px] mx-auto">
            <ShareBtn
               name={property.name}
               id={id}
               zone={property.zone}
               capacity={property.capacity}
               surface={property.mts}
               constructionMts={property.mtsConstruction}
               condoId={(property.condo && property.condo) || ""}
               explicitRent={scheme === "rent"}
               costs={{
                  ...property.costos,
                  maintenance: property.maintenance,
                  salePrice: property.costoVenta,
                  pricePerMeter: property.pricePerMeter,
               }}
               description={property.desc2}
               type={property.type}
               videos={property.videos}
               mapsShareUrl={property.mapsShareUrl}
               accessToBeach={property.accessToBeach}
            />
            <section className="flex flex-col md:flex-row md:items-center justify-between">
               <ArrowBackIcon
                  className="font-bold mb-7 cursor-pointer mt-5 flex"
                  onClick={() => navigate(-1)}
               />
               {user.admin && (
                  <div className="flex gap-4 items-center justify-center">
                     <EditButton
                        label="Editar inmueble"
                        onClick={() =>
                           navigate(`/inmuebles/editInmueble/${id}`, {state: {inmueble: property}})
                        }
                        icon={<EditRoundedIcon fontSize="small" />}
                     />
                     <EditButton
                        label="Editar fotos"
                        onClick={() =>
                           navigate(`/inmuebles/editPhotos/${id}`, {state: {inmueble: property}})
                        }
                        icon={<CameraAltIcon fontSize="small" />}
                     />
                  </div>
               )}
            </section>
            {property.createdAt && (
               <p className="my-5 text-center">
                  Creado el: {dayjs.unix(property.createdAt?._seconds).format("DD-MM-YYYY")}
               </p>
            )}
            <section className="flex gap-4 items-center justify-center flex-wrap">
               <StatusIndicator
                  label={
                     property.available
                        ? "Disponible"
                        : property.available === undefined
                        ? "Sin actualizar"
                        : "No disponible"
                  }
                  condition={property.available}
               />
               <StatusIndicator
                  label={
                     property.suspended
                        ? "Suspendido"
                        : property.suspended === undefined
                        ? "Sin actualizar"
                        : "Sin suspender"
                  }
                  condition={!property.suspended}
               />
               <StatusTile
                  status={property.status}
                  className="border border-gray-300 px-4 py-1 rounded-md"
               />
            </section>
            <section className="my-4 flex items-center justify-center gap-4">
               {property.forSale && <SchemeBtn />}
               {property.forRent && <SchemeBtn sale={false} />}
            </section>
            <section className="grid grid-cols-2 md:flex md:flex-wrap gap-4 items-center justify-center">
               {PROPERTY_SECTIONS.map((section, i) => {
                  if (section.id === "property-calendar" && !property.calendar) return null
                  return (
                     <button
                        key={i}
                        className="bg-blue rounded text-white px-4 py-1"
                        onClick={() => {
                           const element = document.getElementById(section.id)
                           element?.scrollIntoView({behavior: "smooth"})
                        }}
                     >
                        {section.title}
                     </button>
                  )
               })}
            </section>
            <header className="flex flex-col md:flex-row items-center justify-center mb-8 md:min-h-[400px]">
               <img
                  src={property.mainImageUrl}
                  alt="mainImage"
                  className="md:w-[50%] h-auto w-full object-cover rounded-lg mx-auto my-5"
               />
               <div className="md:w-[50%] w-full md:ml-4">
                  <div className="flex items-center">
                     <h1>{property.name}</h1>
                     <button
                        onClick={() => {
                           copyToClipboard()
                        }}
                        disabled={displayCopied}
                     >
                        <ContentCopyIcon className="ml-4" />
                        <em className="font-semibold text-sm ml-1">
                           {displayCopied ? "¡Dirección copiada!" : "Copiar para clientes"}
                        </em>
                     </button>
                  </div>
                  {property.condoName && (
                     <h3
                        className="cursor-pointer underline"
                        onClick={() => {
                           const element = document.getElementById("condo-info")
                           element?.scrollIntoView({behavior: "smooth"})
                        }}
                     >
                        {property.condoName}
                        {property.condoClass && <span> - {property.condoClass}</span>}
                     </h3>
                  )}
                  <p>{property.type}</p>
                  <p>{property.accessToBeach}</p>
                  <div className="flex flex-wrap gap-4 my-4">
                     {PROPERTY_CHARACTERISTICS.map(
                        (c, i) =>
                           property[c.key] && (
                              <div key={i} className="flex items-center gap-2">
                                 {c.icon}
                                 {property[c.key]}
                                 &nbsp;
                                 {c.suffix}
                              </div>
                           ),
                     )}
                  </div>
                  {(property.environments || property.house_environments) && (
                     <>
                        <h3>Incluye</h3>
                        <div className="flex flex-wrap gap-2">
                           {property.environments?.map((a: string, i: number) => (
                              <EnvironmentP env={a} key={i} />
                           ))}
                           {property.house_environments?.map((a: string, i: number) => (
                              <EnvironmentP env={a} key={i} />
                           ))}
                        </div>
                     </>
                  )}
                  <h3 className="mt-4">Ver ubicación</h3>
                  <p className="truncate">
                     <a
                        href={property.mapsShareUrl}
                        target="_blank"
                        className="text-blue underline"
                        rel="noreferrer"
                     >
                        {property.mapsShareUrl}
                     </a>
                  </p>
                  <p className="mb-4">
                     Dirección:{" "}
                     {property.address
                        ? property.address
                        : property.condoAddress
                        ? property.condoAddress
                        : "Sin dirección"}
                  </p>
                  {user.admin && property.saleComission && (
                     <p className="text-green">
                        Comisión de venta pactada: {property.saleComission}%
                     </p>
                  )}
                  {user.admin ? (
                     property.partnerNames ? (
                        <p className="text-green">{`Responsable: ${property.partnerNames.map(
                           (name: string) => name,
                        )}`}</p>
                     ) : (
                        "Sin responsable"
                     )
                  ) : null}
               </div>
            </header>
            <PageSection id="property-info" title="Información" className="flex flex-col gap-4">
               <h2 className="text-blue">Precios</h2>
               <section className="grid grid-cols-1 md:grid-cols-2">
                  <div>
                     {scheme !== "rent" && (
                        <>
                           <h3 className="text-green">Venta</h3>
                           {property.costoVenta && (
                              <p>
                                 Precio de Venta: {`$${property.costoVenta.toLocaleString()} MXN`}
                              </p>
                           )}
                           {property.maintenance && (
                              <p>
                                 Mantenimiento: {`$${property.maintenance.toLocaleString()} MXN`}
                              </p>
                           )}
                           {property.pricePerMeter && (
                              <p>
                                 Precio por metro cuadrado:{" "}
                                 {`$${property.pricePerMeter.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                 })} MXN`}
                              </p>
                           )}
                        </>
                     )}
                  </div>
                  <div>
                     {scheme !== "sale" && (
                        <>
                           <h3 className="text-green">Renta</h3>
                           {property.costos?.mensual_anual && (
                              <p>
                                 Precio mensual anual:{" "}
                                 {`$${property.costos.mensual_anual.toLocaleString()} MXN`}
                              </p>
                           )}
                           {property.costos?.mensual_regular && (
                              <p>
                                 Precio mensual regular:{" "}
                                 {`$${property.costos.mensual_regular.toLocaleString()} MXN`}
                              </p>
                           )}
                           {property.costos?.noche_alta && (
                              <p>
                                 Precio noche alta:{" "}
                                 {`$${property.costos.noche_alta.toLocaleString()} MXN`}
                              </p>
                           )}
                           {property.costos?.noche_regular && (
                              <p>
                                 Precio noche regular:{" "}
                                 {`$${property.costos.noche_regular.toLocaleString()} MXN`}
                              </p>
                           )}
                           {property.costos?.noche_media && (
                              <p>
                                 Precio noche media:{" "}
                                 {`$${property.costos.noche_media.toLocaleString()} MXN`}
                              </p>
                           )}
                        </>
                     )}
                  </div>
               </section>
               {property.desc2 && (
                  <div>
                     <h3 className="text-blue">Descripción Breve</h3>
                     <p className="whitespace-pre-wrap">{property.desc2}</p>
                  </div>
               )}
               {property.desc && (
                  <div>
                     <h3 className="text-blue">Descripción</h3>
                     <p className="text-md whitespace-pre-wrap">{property.desc}</p>
                  </div>
               )}
               <div>
                  <h3 className="text-blue">Información adicional</h3>
                  <ul className="list-disc list-inside">
                     {property.furnished ? (
                        <li>El inmueble esta amueblado.</li>
                     ) : (
                        <li>El inmueble no se encuentra amueblado.</li>
                     )}
                     {property.house_antiquity ? <li>{property.house_antiquity}.</li> : <></>}
                     {property.outing_to_the_beach ? (
                        <li>El inmueble tiene salida a la playa.</li>
                     ) : (
                        <li>El inmueble no tiene salida a la playa.</li>
                     )}
                     {property.seaview ? (
                        <li>El inmueble tiene vista al mar.</li>
                     ) : (
                        <li>El inmueble no tiene vista al mar.</li>
                     )}
                  </ul>
               </div>
               {property.furnished && property.furnishType && (
                  <p className="mb-4">{property.furnishType}</p>
               )}
            </PageSection>
            <PageSection
               id="property-notes"
               title="Notas"
               className="flex flex-col gap-4 items-center"
            >
               <div className="rounded-lg border-2 border-gray-300 w-full md:max-w-[50vw] p-4 whitespace-pre-wrap">
                  <h4 className="text-blue text-center">Notas públicas</h4>
                  <p className="whitespace-pre-wrap truncate">
                     {property.notes ? property.notes : "Sin notas públicas"}
                  </p>
               </div>
               <div className="rounded-lg border-2 border-green w-full md:max-w-[50vw] p-4 whitespace-pre-wrap">
                  <h4 className="text-green text-center">Notas privadas</h4>
                  <p className="text-gray-400 text-sm text-center">
                     Las notas privadas solo podrán verse en el sitio de Casas Acapulco Diamante
                     para Socios
                  </p>
                  <p className="whitespace-pre-wrap truncate">
                     {property.privateNotes ? property.privateNotes : "Sin notas privadas"}
                  </p>
               </div>
            </PageSection>
            <PageSection id="property-files" title="Archivos">
               (Pendiente)
            </PageSection>
            {property.forRent && scheme !== "sale" && (
               <PageSection id="property-calendar" title="Calendario">
                  <div className="text-center my-2">
                     <CalendarForm
                        inmuebleId={id}
                        fetchInmueble={invalidateCache}
                        calendarPast={property.calendar}
                        currDate={showEditCalendarForm ? currDate : null} // Si es edición, pasamos la fecha actual
                        currNote={showEditCalendarForm ? currNote : ""} // Pasamos las notas si es edición
                        apiDate={showEditCalendarForm ? date : null} // Pasamos apiDate si es edición
                        hideForm={showEditCalendarForm ? hideEditCalendarForm : undefined}
                     />
                  </div>
                  <TableContainer component={Paper}>
                     <Table sx={{minWidth: 700}} aria-label="simple table">
                        <TableHead>
                           <TableRow>
                              <TableCell className="justify-center" align="center">Ocupación Propietario / CAD</TableCell>
                              <TableCell className="justify-center" align="center">Notas</TableCell>
                              <TableCell align="center">Desde</TableCell>
                              <TableCell align="center">Hasta</TableCell>
                              <TableCell align="center">Editar</TableCell>
                              <TableCell align="center">Eliminar</TableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody sx={{width: "100%"}}>
                           {property?.calendar?.map((date: any, index: number) => (
                              <TableRow
                                 key={index}
                                 sx={{
                                    "&:last-child td, &:last-child th": {
                                       border: 0,
                                    },
                                 }}
                              >
                                 <TableCell
                                    align="center"
                                    sx={{
                                       maxWidth: "120px",
                                       overflowWrap: "break-word",
                                    }}
                                 >
                                    {date.rentedBy}
                                 </TableCell>
                                 <TableCell
                                    sx={{
                                       maxWidth: "120px",
                                       overflowWrap: "break-word",
                                    }}
                                 >
                                    {date.notes}
                                 </TableCell>
                                 <TableCell align="center">
                                    {dayjs.unix(date.start._seconds).format("DD-MM-YYYY")}
                                 </TableCell>
                                 <TableCell align="center">
                                    {dayjs.unix(date.end._seconds).format("DD-MM-YYYY")}
                                 </TableCell>
                                 <TableCell align="center">
                                    {!showEditCalendarForm && (
                                       <EditIcon
                                          className="text-green-600 self-center cursor-pointer"
                                          onClick={() => handleEdit(date)}
                                       />
                                    )}
                                    {showEditCalendarForm && (
                                       <EditIcon className="text-gray-400 self-center cursor-pointer" />
                                    )}
                                 </TableCell>
                                 <TableCell align="center">
                                    {!disableDelete && (
                                       <DeleteIcon
                                          className="text-red-600 self-center cursor-pointer"
                                          onClick={() => deleteDate(index)}
                                       />
                                    )}
                                    {disableDelete && (
                                       <DeleteIcon className="text-gray-400 self-center cursor-pointer" />
                                    )}
                                 </TableCell>
                              </TableRow>
                           ))}
                        </TableBody>
                     </Table>
                  </TableContainer>
               </PageSection>
            )}
            <PageSection id="property-videos" title="Videos" className="flex flex-col items-center">
               {property.videos?.map((url: string, index: number) => {
                  if (url.includes("youtube")) {
                     return (
                        <div key={index}>
                           <div className="md:hidden">
                              <YouTubeEmbed url={url} width={300} height={200} />
                           </div>
                           <div className="md:flex hidden">
                              <YouTubeEmbed url={url} width={700} height={400} />
                           </div>
                        </div>
                     )
                  } else if (url.includes("tiktok")) {
                     return (
                        <div key={index} className="flex">
                           <TikTokEmbed url={url} />{" "}
                        </div>
                     )
                  } else {
                     return null
                  }
               })}
            </PageSection>
            <PageSection id="property-location" title="Ubicación">
               <section className="flex flex-col items-center text-center mx-4 md:mx-[20%] max-w-full">
                  {property.mapsUrl && (
                     <iframe
                        src={property.mapsUrl}
                        className="my-6 h-[200px] w-[300px] md:h-[450px] md:w-[700px] mx-auto"
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                     />
                  )}
                  {property.mapsShareUrl && (
                     <>
                        <p className="font-bold">Ver Ubicación</p>
                        <a
                           href={property.mapsShareUrl}
                           target="_blank"
                           rel="noreferrer"
                           className="text-blue underline truncate"
                        >
                           {property.mapsShareUrl}
                        </a>
                     </>
                  )}
                  {property.address && (
                     <p className="my-4 text-sm md:mx-[20%]">Dirección: {property.address}</p>
                  )}
               </section>
            </PageSection>
            <PageSection id="property-images" title="Imágenes">
               {property.images && <ImagesColumn id={property.id} source="property" />}
            </PageSection>
            <hr className="font-bold text-2xl mb-10"></hr>
            {property.condo && (
               <CondominioInfo condId={property.condo} styles={styles} shouldNavigate />
            )}
         </Page>
      )
   }

   return (
      <Page>
         <></>
      </Page>
   )
}

export default PropertyPage
