import React, {useState} from "react"
import {Link} from "react-router-dom"
import {Alert} from "@mui/material"
import LockIcon from "@mui/icons-material/Lock"
import useLogin from "@/hooks/useLogin"
import {checkUser} from "@/api/usuario"
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail"
import Checkbox from "@mui/material/Checkbox"
import AuthPage from "@/layouts/AuthPage"
import TextInput from "@/components/ui/TextInput"

function Login() {
   const [email, setEmail] = useState("")
   const [password, setPassword] = useState("")
   const [passwordVisible, setPasswordVisible] = useState(false)
   const [workerError, setWorkerError] = useState<null | string>(null)
   const {error, isPending, login} = useLogin()

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      setWorkerError(null)
      try {
         const user = await checkUser(email)
         if (!user.data.user.isTrabajador && !user.data.user.isAdmin) {
            setWorkerError("No eres un trabajador o administrador de la organización")
            return
         }
         login(email, password, window.location.origin)
      } catch (error) {
         setWorkerError("No existe un usuario con ese correo electronico")
      }
   }

   return (
      <AuthPage title="Bienvenido a Casas Acapulco Diamante para Socios">
         <p>
            Inicia sesión para continuar. Si aún no cuentas con un usuario, por favor ponte en
            contacto con nosotros.
         </p>
         <form className="flex flex-col items-center gap-4" onSubmit={handleSubmit}>
            {error && (
               <Alert className="mb-7" severity="error">
                  {error}
               </Alert>
            )}
            {workerError && (
               <Alert className="mb-7" severity="error">
                  {workerError}
               </Alert>
            )}
            <TextInput
               icon={<AlternateEmailIcon />}
               placeholder="Correo electrónico *"
               type="email"
               value={email}
               onChange={(e) => setEmail(e.target.value)}
            />
            <TextInput
               icon={<LockIcon />}
               placeholder="Contraseña *"
               type={passwordVisible ? "text" : "password"}
               value={password}
               onChange={(e) => setPassword(e.target.value)}
            />
            <div className="flex items-center">
               <Checkbox
                  checked={passwordVisible}
                  onChange={() => setPasswordVisible(!passwordVisible)}
                  color="secondary"
               />
               <p>Mostrar contraseña</p>
            </div>
            {!isPending && (
               <button
                  className="border rounded-xl bg-green hover:bg-gray-500 py-1 px-4 text-white"
                  type="submit"
               >
                  Iniciar sesión
               </button>
            )}
            {isPending && (
               <button
                  disabled
                  className="border rounded-xl bg-blue py-1 px-4 text-white animate-pulse"
               >
                  Cargando...
               </button>
            )}
         </form>
         <Link className="italic underline text-gray-400" to="/reset-password">
            ¿Olvidaste tu contraseña?
         </Link>
         <Link className="italic underline text-gray-400" to="/signup">
            Registrar nuevo usuario{" "}
         </Link>
      </AuthPage>
   )
}

export default Login
