import React, {useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import EditIcon from "@mui/icons-material/Edit"
import AddIcon from "@mui/icons-material/Add"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import {getAssociates} from "@/api/asociados"
import Page from "@/layouts/Page"
import {Link} from "react-router-dom"

function Asociates() {
   const [loading, setLoading] = useState(false)
   const [asociates, setAsociates] = useState([])
   const navigate = useNavigate()

   useEffect(() => {
      setLoading(true)
      fetchAsociates()
   }, [])

   const fetchAsociates = async () => {
      const res: any = await getAssociates()
      setAsociates(res)
      setLoading(false)
   }
   return (
      <Page className="flex flex-col items-center py-12 gap-8">
         <h1 className="text-blue text-3xl font-bold">Asociados</h1>
         <Link
            to="/asociados/add"
            className="rounded-full bg-blue text-white shadow-lg px-6 py-1 flex gap-2 items-center"
         >
            <AddIcon fontSize="large" />
            Agregar asociado
         </Link>
         {loading ? (
            <p className="text-xl font-bold">Cargando...</p>
         ) : (
            <TableContainer
               component={Paper}
               className="max-w-[1200px] overflow-x-scroll"
               sx={{
                  overflowX: "scroll",
               }}
            >
               <Table className="items-center" aria-label="simple table">
                  <TableHead>
                     <TableRow>
                        <TableCell align="center">Tipo</TableCell>
                        <TableCell align="center">Nombre</TableCell>
                        <TableCell align="center">Número</TableCell>
                        <TableCell align="center">Correo</TableCell>
                        <TableCell align="center">Editar</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {asociates.map((row: any) => (
                        <TableRow
                           key={row.name}
                           sx={{"&:last-child td, &:last-child th": {border: 0}}}
                        >
                           <TableCell align="center">{row.type}</TableCell>
                           <TableCell align="center">{row.name}</TableCell>
                           <TableCell align="center">{row.number}</TableCell>
                           <TableCell align="center">{row.email || "NA"}</TableCell>
                           <TableCell align="center">
                              <EditIcon
                                 style={{cursor: "pointer"}}
                                 onClick={() => navigate(`/asociados/edit/${row.id}`, {state: row})}
                                 className="text-gray-500"
                              />
                           </TableCell>
                        </TableRow>
                     ))}
                  </TableBody>
               </Table>
            </TableContainer>
         )}
      </Page>
   )
}

export default Asociates
