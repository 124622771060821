/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useEffect} from "react"
// styles
import {inmAndCondStyles} from "../../constants"
// auth context
import {useAuthContext} from "../../hooks/useAuthContext"
import {getUser} from "../../api/usuario"
// TextField & material UI
import {Button} from "@mui/material"
// components
import EditProfile from "../../components/profile/EditProfile"
import Page from "../../layouts/Page"
import {useLogout} from "@/hooks/useLogout"

function Profile() {
   // state
   const [loading, setLoading] = useState(false)
   const {user} = useAuthContext()
   const [error, setError] = useState<string | boolean>(false)
   const [name, setName] = useState("")
   const [showEditForm, setShowEditForm] = useState(false)
   const [phone, setPhone] = useState("")
   const {logout} = useLogout()

   const fetchUser = async () => {
      try {
         setLoading(true)
         // fetch user
         const fetchedUser = await getUser(user?.email)
         setName(fetchedUser.data.user.nombre)
         setPhone(fetchedUser.data.user.telefono)
         // set user
         setLoading(false)
      } catch (error) {
         console.log(error)
         setError("Error al cargar los datos del usuario")
      }
   }

   useEffect(() => {
      fetchUser()
   }, [])

   const hideForm = () => {
      setShowEditForm(false)
   }

   return (
      <Page>
         <>
            {loading && <h2 className={inmAndCondStyles.loading}>Cargando...</h2>}
            {!loading && (
               <div className="text-center my-auto flex flex-col">
                  <h3 className="font-bold text-2xl mt-10 mb-4">
                     ¡Hola, {name}! ¡Bienveido de vuelta!
                  </h3>
                  <p className="mb-4 text-blue">Rol: {user.admin ? "Administrador" : "Socio"}</p>
                  {!showEditForm && (
                     <Button
                        variant="contained"
                        style={{
                           color: "white",
                           maxWidth: "200px",
                           margin: "auto",
                        }}
                        onClick={() => setShowEditForm(true)}
                     >
                        Editar mis datos
                     </Button>
                  )}
                  {showEditForm && (
                     <EditProfile
                        hideForm={hideForm}
                        name={name}
                        phone={phone}
                        fetchUser={fetchUser}
                     />
                  )}
                  <button
                     onClick={logout}
                     className="px-4 py-1 bg-gray-500 text-white rounded-md mt-20 max-w-[200px] mx-auto"
                  >
                     Cerrar sesión
                  </button>
               </div>
            )}
         </>
      </Page>
   )
}

export default Profile
