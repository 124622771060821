import React, {useState} from "react"
import {useLocation} from "react-router-dom"
import {Alert} from "@mui/material"
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail"
import LockIcon from "@mui/icons-material/Lock"
import LockOpenIcon from "@mui/icons-material/LockOpen"
import PersonIcon from "@mui/icons-material/Person"
import PhoneIcon from "@mui/icons-material/Phone"
import useLogin from "@/hooks/useLogin"
import {CheckSolicitud, DeleteSolicitud} from "@/api/solicitud"
import {AddUser} from "@/api/usuario"
import {API_URL} from "@/constants"
import AuthPage, {GoToLogin} from "@/layouts/AuthPage"
import Checkbox from "@mui/material/Checkbox"
import TextInput from "@/components/ui/TextInput"

function CreateAccount() {
   const [name, setName] = useState("")
   const [phone, setPhone] = useState("")
   const [password, setPassword] = useState("")
   const [confirmPassword, setConfirmPassword] = useState("")
   const [passwordsVisible, setPasswordsVisible] = useState(false)
   const [workerError, setWorkerError] = useState<null | string>(null)
   const [isPending, setIsPending] = useState(false)
   const location = useLocation()
   const {email, key} = location.state
   const {error, login} = useLogin()

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      setIsPending(true)
      setWorkerError(null)
      if (password !== confirmPassword) {
         setIsPending(false)
         setWorkerError("Contraseñas deben de ser iguales")
         return
      }
      // check if the email is in the solicitudes collection
      try {
         const solicitud = await CheckSolicitud(email, key)
         // add user to the usuarios collection
         const user = {
            email: email,
            nombre: name,
            telefono: phone,
            type: solicitud.data.solicitud.type,
         }
         const headers = {
            "Content-Type": "application/json",
         }
         const userAdded = await AddUser(user, headers)
         if (userAdded.data.success !== 1) {
            setIsPending(false)
            setWorkerError("Ese correo no esta autorizado")
            return
         }
         // create user with email and password with api
         const res = await fetch(`${API_URL}/usuarios/signup`, {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
            },
            body: JSON.stringify({
               email,
               password,
            }),
         })
         const parsedRes = await res.json()
         if (!parsedRes.success) {
            setIsPending(false)
            setWorkerError("No se pudo crear la cuenta")
            return
         }
         // delete the solicitud document
         await DeleteSolicitud(email)
         // login the user automatically
         login(email, password, window.location.origin)
         setIsPending(false)
      } catch (error) {
         setIsPending(false)
         setWorkerError(error.response.data.msg)
      }
   }

   return (
      <AuthPage title="Crea tu cuenta">
         <p>
            Completa los campos del formulario con tu correo de registro para continuar con tu
            registro.
         </p>
         <form action="submit" onSubmit={handleSubmit} className="flex flex-col gap-4 items-center">
            {error && (
               <Alert className="mb-7" severity="error">
                  {error}
               </Alert>
            )}
            {workerError && (
               <Alert className="mb-7" severity="error">
                  {workerError}
               </Alert>
            )}
            <TextInput
               icon={<PersonIcon />}
               placeholder="Nombre"
               type="text"
               value={name}
               onChange={(e) => setName(e.target.value)}
               required
            />
            <TextInput
               icon={<PhoneIcon />}
               placeholder="Teléfono"
               type="text"
               value={phone}
               onChange={(e) => setPhone(e.target.value)}
               required
            />
            <TextInput
               icon={<AlternateEmailIcon />}
               placeholder="Correo"
               type="email"
               value={email}
               required
               disabled
            />
            <TextInput
               icon={<LockOpenIcon />}
               placeholder="Contraseña"
               type={passwordsVisible ? "text" : "password"}
               value={password}
               onChange={(e) => setPassword(e.target.value)}
               required
            />
            <TextInput
               icon={<LockIcon />}
               placeholder="Confirmar contraseña"
               type={passwordsVisible ? "text" : "password"}
               value={confirmPassword}
               onChange={(e) => setConfirmPassword(e.target.value)}
               required
            />
            <div className="flex items-center">
               <Checkbox
                  checked={passwordsVisible}
                  onChange={() => setPasswordsVisible(!passwordsVisible)}
                  color="secondary"
               />
               <p>Mostrar contraseña</p>
            </div>
            {!isPending && (
               <button
                  className="border rounded-xl bg-green hover:bg-gray-500 py-1 px-4 text-white"
                  type="submit"
               >
                  Crear cuenta
               </button>
            )}
            {isPending && (
               <button
                  disabled
                  className="border rounded-xl bg-blue py-1 px-4 text-white animate-pulse"
               >
                  Cargando...
               </button>
            )}
         </form>
         <GoToLogin />
      </AuthPage>
   )
}

export default CreateAccount
