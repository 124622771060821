import {PropertyType} from "./types/Property"

export function standarizeName(name: string) {
   const normalized = name
      .normalize("NFD") // Normalize to decomposed form
      .replace(/[\u0300-\u036f]/g, "") // Remove diacritic marks (tildes and such)
      .toLowerCase()
      .replace(/[^\w\s-]/g, "")
      .replace(/\s+/g, "-")

   return normalized
}

export const getTypeForURL = (type: string) => {
   return type === PropertyType.HOUSE
      ? "casas"
      : type === PropertyType.APARTMENT
      ? "departamentos"
      : "terrenos"
}

export const isMobile = () => {
   return window.innerWidth <= 768
}

/**
 * Clears whatever we have stored in localStorage as cache.
 */
export const clearCache = () => {
   localStorage.removeItem("fetchedPartners")
   localStorage.removeItem("condos")
   localStorage.removeItem("properties")
}
