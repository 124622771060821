/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useEffect} from "react"
import {Accordion, AccordionSummary, AccordionDetails} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {getImages} from "../../api/images"

interface Props {
   id: string
   source: string
}

export default function ImagesColumn({id, source}: Props) {
   const [images, setImages] = useState({})
   useEffect(() => {
      getImages(id, source).then((images) => {
         setImages(images)
      })
   }, [])

   return (
      <>
         {Object.entries(images).map(([key, value], index) => (
            <CollapsableSection title={key} key={index} images={value as string[]} />
         ))}
      </>
   )
}

interface SectionProps {
   title: string
   images: string[]
}

function CollapsableSection(props: SectionProps) {
   const [show, setShow] = useState(false)

   return (
      <Accordion
         expanded={show}
         onChange={() => {
            setShow(!show)
         }}
         className="my-2"
      >
         <AccordionSummary expandIcon={<ExpandMoreIcon />} className="font-bold">
            {props.title}
         </AccordionSummary>
         <AccordionDetails>
            <div className="mt-6 mb-6 grid grid-cols-1 md:grid-cols-2 gap-6 mx-6">
               {props.images.map((image: string) => (
                  <img key={image} src={image} className="rounded-lg md:h-[300px] mx-auto" alt="" />
               ))}
            </div>
         </AccordionDetails>
      </Accordion>
   )
}
