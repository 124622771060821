import {useState, useEffect} from "react"
import {useAuthContext} from "./useAuthContext"
import {API_URL} from "../constants"

export default function useLogin() {
   // state for different values
   const [isCancelled, setIsCancelled] = useState(false)
   const [error, setError] = useState<null | string>(null)
   const [isPending, setIsPending] = useState(false)
   // get the dispatch function of the auth context,
   // this will let us change the state of the reducer
   const {dispatch} = useAuthContext()

   // login function that is called in the LOGIN PAGE
   const login = async (email: string, password: string, origin: string) => {
      setError(null)
      setIsPending(true)

      try {
         // sign in the given user
         //  PREVIO A DESCONEXIÓN DE AUTH ->  const userCredentials = await signInWithEmailAndPassword(auth, email, password)
         const response = await fetch(`${API_URL}/usuarios/login`, {
            method: "POST",
            mode: "cors",
            headers: {
               "Content-Type": "application/json",
               Origin: origin,
            },
            credentials: "include",
            body: JSON.stringify({email, password}),
         })
         const parsedRes = await response.json()
         // dispatch the user
         //  PREVIO A DESCONEXIÓN DE AUTH -> dispatch({type: "LOGIN", payload: userCredentials.user})
         dispatch({type: "LOGIN", payload: parsedRes.data})
         if (!isCancelled) {
            setIsPending(false)
            setError(null)
         }
      } catch (err) {
         if (!isCancelled) {
            console.log(err)
            setIsPending(false)
            setError("Correo electronico o contraseña incorrecta")
         }
      }
   }

   // if it gets cancellled, it stops the execution
   useEffect(() => {
      setIsCancelled(false)
      return () => setIsCancelled(true)
   }, [])

   return {error, isPending, login}
}
