import React, {useState, useEffect} from "react"
import {useLocation, useNavigate} from "react-router-dom"
import {ReactSortable} from "react-sortablejs"
import {nanoid} from "nanoid"
import DeleteIcon from "@mui/icons-material/Delete"
import AddIcon from "@mui/icons-material/Add"
import SaveIcon from "@mui/icons-material/Save"
import {Alert, Button} from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import EditIcon from "@mui/icons-material/Edit"
import AttachFileIcon from "@mui/icons-material/AttachFile"
import UploadImagesForm from "@/components/ui/UploadImagesForm"
import Page from "@/layouts/Page"
import {
   orderImages as apiOrderImages,
   orderSections,
   mainImageChanges,
   deleteImages as apiDeleteImages,
   deleteSectionImages,
   deleteMainImage,
   uploadNewImages,
} from "@/api/inmueble"
import {isMobile} from "@/utils"
import ConfirmationDialog from "@/components/ui/ConfirmationDialog"

function EditPhotos() {
   const location = useLocation()
   const navigate = useNavigate()
   const [inmueble, setInmueble] = useState<any>(null)
   const [secciones, setSecciones] = useState<any>([])
   const [seccion, setSeccion] = useState("")
   const [error, setError] = useState<boolean | string>(false)
   const [loading, setLoading] = useState(false)
   const [createLoading, setCreateLoading] = useState(true)
   const [mainImage, setMainImage] = useState<any>(null)
   const [previousMainImage, setPreviousMainImage] = useState<any>(null)
   const [images, setImages] = useState<any>()
   const [currImage, setCurrImage] = useState<any>(null)
   const [showImageForm, setShowImageForm] = useState(false)
   const [deletedIamges, setDeletedImages] = useState<any>()
   const [newImages, setNewImages] = useState<any>()
   const id = location.pathname.split("/")[3]
   const [confirmationOpen, setConfirmationOpen] = useState(false)
   const [categoryToDelete, setCategoryToDelete] = useState(0)

   useEffect(() => {
      setCreateLoading(true)
      const state: any = location.state
      setInmueble(state.inmueble)
      setSecciones(
         state.inmueble?.orderedSections?.map((c: any) => {
            return {
               id: nanoid(),
               name: c,
            }
         }) || [],
      )
      setPreviousMainImage(state.inmueble?.mainImage)
      setImages(state.inmueble?.images)
      const deleteImagesEmpty: any = {}
      state.inmueble?.orderedSections?.forEach((c: any) => {
         deleteImagesEmpty[c] = []
      })
      setDeletedImages(deleteImagesEmpty)
      setCreateLoading(false)
   }, [])

   // helper functions
   // whenever a file is selected we set the file state to that file
   const imageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      setMainImage(null)
      setError(false)
      if (!e.target.files || e.target.files.length === 0) {
         setError("Selecciona una imagen")
         return
      }
      if (!e.target.files[0].type.includes("image")) {
         setError("Solo se aceptan imagenes")
         return
      }
      if (
         e.target.files[0].name.endsWith("jpg") ||
         e.target.files[0].name.endsWith("png") ||
         e.target.files[0].name.endsWith("jpeg") ||
         e.target.files[0].name.endsWith("JPEG") ||
         e.target.files[0].name.endsWith("JPG") ||
         e.target.files[0].name.endsWith("PNG")
      ) {
         setMainImage(e.target.files[0])
         setError(false)
      } else {
         setError("Solo se aceptan imagenes jpeg o png")
         return
      }
   }

   const handleSeccionAdd = () => {
      setError(false)
      // if no category was added then an error is set
      if (seccion === "") {
         setError("Ingresa una categoria")
         return
      }
      // if the category already exists then an error is set
      if (secciones.find((s: any) => s.name === seccion)) {
         setError("La categoria ya existe")
         return
      }
      // if the category list already exists we just add the new category to it
      // and also to the images object, which will be empty until
      // it is change in the backend
      if (secciones) {
         setSecciones((prevSecciones: any) => [
            ...prevSecciones,
            {
               id: nanoid(),
               name: seccion.trim(),
            },
         ])
      }
      setSeccion("")
   }

   // helper functions
   // everytime the user deletes a category, we remove it from the categories state
   const handleCategoryDelete = (index: number) => {
      const seccionesUpdated = secciones.filter((s: any) => {
         return s.id !== secciones[index].id
      })
      setSecciones(seccionesUpdated)
      delete images[secciones[index].name]
      setImages(images)
      if (newImages) {
         delete newImages[secciones[index].name]
      }
   }

   // show image form
   const handleShowimageForm = (index: number) => {
      setCurrImage(secciones[index].name)
      setShowImageForm(true)
   }

   const addImages = (images: any, imageCategory: string, updated: any, uploadedImages: any) => {
      setImages((prevImages: any) => {
         prevImages[imageCategory] = [...images]
         return prevImages
      })
      setDeletedImages(updated)
      setNewImages(uploadedImages)
      setShowImageForm(false)
   }

   const handleSubmit = async () => {
      try {
         setError(false)
         setLoading(true)
         if (secciones.length === 0) {
            setError("Debes agregar al menos una categoria")
            setLoading(false)
            return
         }
         if (mainImage) {
            //  formData to send the file to the server
            const formData = new FormData()
            formData.append("mainImage", mainImage)

            //   eliminate the image that was already uploaded
            await deleteMainImage(id, previousMainImage)

            //   petition
            await mainImageChanges(id, formData)
         }
         // update the categories
         const categoriesUpdated = secciones.map((c: any) => {
            return c.name
         })

         // delete section images if needed
         const apiImages = await deleteSectionImages(
            id,
            inmueble.orderedSections,
            categoriesUpdated,
            inmueble.images,
         )
         // petition to the server
         await orderSections(id, apiImages, categoriesUpdated)
         // delete images if needed
         let deletedImagesBool = false
         for (const seccion of categoriesUpdated) {
            if (deletedIamges[seccion] === undefined) {
               deletedImagesBool = true
               break
            }
            if (deletedIamges[seccion].length > 0) {
               deletedImagesBool = true
            }
         }
         if (deletedImagesBool) {
            await apiDeleteImages(id, deletedIamges)
         }
         // upload new Images
         let noNewImages = true
         if (newImages) {
            const imageKeys = Object.keys(newImages)
            for (const key of imageKeys) {
               if (newImages[key].length > 0) {
                  noNewImages = false
               }
            }
         }
         let newPaths: any
         if (!noNewImages) {
            const formData = new FormData()
            const imageKeys = Object.keys(newImages)
            for (const key of imageKeys) {
               for (const image of newImages[key]) {
                  formData.append(key, image)
               }
            }
            newPaths = await uploadNewImages(id, formData)
         }
         if (newPaths) {
            const keys = Object.keys(newPaths)
            for (const key of keys) {
               let counter = 0
               for (let i = 0; i < images[key].length; i++) {
                  if (typeof images[key][i] !== "string") {
                     images[key][i] = newPaths[key][counter]
                     counter++
                  }
               }
            }
         }
         // order images
         await apiOrderImages(id, images, inmueble)
         navigate(`/inmuebles/${id}`)
         setLoading(false)
      } catch (error) {
         setLoading(false)
         setError("No se pudo hacer la actualización")
      }
   }

   return (
      <Page>
         {!createLoading && (
            <>
               {!showImageForm ? (
                  <ArrowBackIcon
                     className="font-bold mb-7 cursor-pointer mt-5"
                     onClick={() => navigate(-1)}
                  />
               ) : (
                  <ArrowBackIcon
                     className="font-bold mb-7 cursor-pointer mt-5"
                     onClick={() => setShowImageForm(false)}
                  />
               )}
               {!showImageForm ? (
                  <div className="flex flex-col gap-4 items-center justify-center">
                     <h1 className="text-center font-bold text-blue text-2xl">
                        Editar imágenes: {inmueble.name}
                     </h1>
                     {error && (
                        <Alert severity="error" className="mb-4 w-1/2 mx-auto">
                           {error}
                        </Alert>
                     )}
                     <div className="md:w-[40%] mx-auto flex-col space-x-2">
                        <Button
                           className="w-full my-auto mx-[2%] flex space-x-2"
                           startIcon={<AttachFileIcon />}
                           variant="outlined"
                           onClick={() => {
                              const fileInput: any = document.querySelector("#file_input")
                              fileInput?.click()
                           }}
                        >
                           {mainImage ? mainImage.name : "Modificar imagen principal"}
                        </Button>
                        <input
                           id="file_input"
                           required
                           type="file"
                           style={{display: "none"}}
                           accept=".jpg,.png,.jpeg"
                           onChange={(e) => imageHandler(e)}
                        />
                     </div>
                     <div className="md:w-1/2 mx-auto">
                        <ReactSortable
                           list={secciones}
                           setList={setSecciones}
                           className="grid grid-cols-1 gap-4 mb-4"
                           delay={isMobile() ? 200 : 0}
                        >
                           {secciones?.length > 0 &&
                              secciones?.map((seccion: any, index: number) => (
                                 <div
                                    key={seccion.id}
                                    className="flex justify-between border border-gray-400 rounded-lg px-8 py-4"
                                 >
                                    <p className="overflow-x-scroll overflow-y-hidden font-bold">
                                       {seccion.name}
                                    </p>
                                    <div className="flex gap-2">
                                       <button
                                          className="text-green self-center"
                                          onClick={() => handleShowimageForm(index)}
                                       >
                                          <EditIcon />
                                       </button>
                                       <button
                                          className="text-red self-center"
                                          onClick={() => {
                                             setConfirmationOpen(true)
                                             setCategoryToDelete(index)
                                          }}
                                       >
                                          <DeleteIcon />
                                       </button>
                                    </div>
                                 </div>
                              ))}
                        </ReactSortable>
                     </div>
                     <div className="flex space-x-2 border p-2 rounded-lg">
                        <input
                           placeholder="Nueva categoría"
                           type="text"
                           className="rounded-lg px-4 py-1 outline-none"
                           value={seccion ? seccion : ""}
                           onChange={(e) => setSeccion(e.target.value)}
                        />
                        <button onClick={handleSeccionAdd}>
                           <AddIcon className="text-blue self-center" />
                        </button>
                     </div>
                     {!loading ? (
                        <>
                           <button
                              onClick={handleSubmit}
                              className="bg-green text-white px-4 py-1 my-4 rounded-lg"
                           >
                              Guardar cambios <SaveIcon />
                           </button>
                           <button
                              onClick={() => navigate(-1)}
                              className="bg-gray-500 text-white px-4 py-1 my-4 rounded-lg"
                           >
                              Cancelar
                           </button>
                        </>
                     ) : (
                        <div className="flex justify-center">
                           <Button
                              variant="contained"
                              style={{
                                 backgroundColor: "#2563eb",
                                 color: "white",
                                 marginTop: "10px",
                              }}
                           >
                              Cargando...
                           </Button>
                        </div>
                     )}
                     <ConfirmationDialog
                        open={confirmationOpen}
                        message="¿Eliminar categoría de imágenes?"
                        onConfirm={() => {
                           setConfirmationOpen(false)
                           handleCategoryDelete(categoryToDelete)
                        }}
                        onCancel={() => setConfirmationOpen(false)}
                     />
                  </div>
               ) : (
                  <UploadImagesForm
                     addImages={addImages}
                     imageName={currImage}
                     images={images[currImage]}
                     deleteImagesEmpty={deletedIamges}
                     newImages={newImages}
                  />
               )}
            </>
         )}
      </Page>
   )
}

export default EditPhotos
