import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import {AuthContextProvider} from "./context/AuthContext"
import {createTheme, ThemeProvider} from "@mui/material/styles"
import {CADBLUE, CADGRAYTEXT, CADGREEN} from "./constants"

const theme = createTheme({
   palette: {
      primary: {
         main: CADBLUE,
         // light: will be calculated from palette.primary.main,
         // dark: will be calculated from palette.primary.main,
         // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
         main: CADGREEN,
         //    light: "#F5EBFF",
         //    // dark: will be calculated from palette.secondary.main,
         //    contrastText: "#47008F",
      },
   },
   // Style overrides for MUI components
   components: {
      // Sets override for all instances of MuiTableCell
      MuiTableCell: {
         styleOverrides: {
            root: {
               color: CADGRAYTEXT,
            },
         },
      },
      MuiTextField: {
         variants: [
            // variants will help you define the props given by Mui and the styles you want to apply for it
            {
               props: {disabled: true},
               style: {
                  backgroundColor: "lightgray",
               },
            },
         ],
      },
      MuiSelect: {
         variants: [
            {
               props: {disabled: true},
               style: {
                  backgroundColor: "lightgray",
               },
            },
         ],
      },
   },
})

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
   <AuthContextProvider>
      <ThemeProvider theme={theme}>
         <App />
      </ThemeProvider>
   </AuthContextProvider>,
)
