/* eslint-disable @typescript-eslint/no-explicit-any */
// axios
import axios from "axios"
// API_URL
import {API_URL} from "../constants"

export async function checkUser(email: string) {
   const user = await axios.get(`${API_URL}/usuarios/usuario/${email}`)
   return user
}

interface User {
   email: string
   nombre: string
   telefono: string
   type: any
}

export async function AddUser(user: User, headers: any) {
   const userAdded = await axios({
      method: "post",
      url: `${API_URL}/usuarios/usuario`,
      data: user,
      headers,
      withCredentials: true,
   })
   return userAdded
}

export async function getUser(id: string) {
   const user = await axios({
      method: "get",
      url: `${API_URL}/usuarios/usuario/${id}`,
      withCredentials: true,
      headers: {},
   })
   return user
}

export async function updateUser(id: string, nombre: string, telefono: string) {
   await axios({
      method: "put",
      url: `${API_URL}/usuarios/usuario/${id}`,
      data: {
         nombre,
         telefono,
      },
      withCredentials: true,
      headers: {
         "Content-Type": "application/json",
      },
   })
}

export async function sendResetPasswordEmail(email: string) {
   const response = await fetch(`${API_URL}/usuarios/${email}/sendResetPasswordEmail`, {
      method: "GET",
   })
   const data = await response.json()
   return data
}

export async function verifyEmail(email: string) {
   const response = await fetch(`${API_URL}/usuarios/verifyEmail/${email}`, {
      method: "GET",
      mode: "cors",
      credentials: "include",
      headers: {
         "Content-Type": "application/json",
         Origin: window.location.origin,
      },
   })
   const data = await response.json()
   return data
}

export async function changePassword(newPassword: string) {
   const response = await fetch(`${API_URL}/usuarios/resetPassword`, {
      method: "PUT",
      headers: {
         "Content-Type": "application/json",
         Origin: window.location.origin,
      },
      credentials: "include",
      body: JSON.stringify({
         newPassword: newPassword,
      }),
   })
   const data = await response.json()
   return data
}
