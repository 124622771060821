import React from "react"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import {CLIENTS_PROD, OLDCAD_URL} from "../constants"
import {Link} from "react-router-dom"
import {useLocation} from "react-router-dom"

const menuData = [
   {label: "Inmuebles", href: "/inmuebles"},
   {label: "Condominios", href: "/condominios"},
   {label: "Usuarios", href: "/usuarios"},
   {label: "Asociados", href: "/asociados"},
   {label: "Temporadas", href: "/temporadas"},
]

export default function Page({
   children,
   className = "",
}: {
   children: React.ReactNode
   className?: string
}) {
   const {pathname} = useLocation()

   return (
      <main className="text-gray-500">
         <header className="h-[100px] bg-blue flex items-center md:justify-center justify-between px-8 gap-8">
            <BurgerMenu />
            <Link to="/">
               <img
                  src="/logos/white.png"
                  alt="Casas Acapulco Diamante Logo"
                  width={80}
                  height={60}
               />
            </Link>
            <div className="rounded-lg md:flex bg-white border-[0.1px] border-gray-400 text-sm hidden">
               {menuData.map((item, i) => (
                  <Link
                     key={i}
                     to={item.href}
                     className={`text-blue px-8 py-1 border-gray-400 border-r hover:bg-gray-400 hover:text-white ${
                        i === menuData.length - 1 && "border-r-0"
                     }
                     ${
                        pathname.includes(item.href) &&
                        `bg-green text-white ${
                           i === menuData.length - 1
                              ? "rounded-r-lg"
                              : i === 0
                              ? "rounded-l-lg"
                              : ""
                        }`
                     }
                     `}
                  >
                     {item.label}
                  </Link>
               ))}
            </div>
            <Link to="/perfil">
               <AccountCircleIcon className="text-white hidden md:flex" fontSize="large" />
            </Link>
         </header>
         <div className={`min-h-[70vh] p-4 ${className}`}>{children}</div>
         <Footer />
      </main>
   )
}

export function Footer() {
   return (
      <footer className="h-auto bg-blue flex flex-col items-center text-white py-4 md:py-10 text-center w-full">
         <h5 className="font-bold md:text-lg mx-4">© Copyright 2024 Casas Acapulco Diamante</h5>
         <p className="mt-8">Sitios web de clientes</p>
         <div className="flex md:gap-2 flex-col md:flex-row">
            <a href={CLIENTS_PROD} className="underline" target="_blank" rel="noreferrer">
               {CLIENTS_PROD}
            </a>
            <p className="hidden md:flex">|</p>
            <a href={OLDCAD_URL} className="underline" target="_blank" rel="noreferrer">
               {OLDCAD_URL}
            </a>
         </div>
      </footer>
   )
}

// BurgerMenu component
import {useState} from "react"
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import CloseIcon from "@mui/icons-material/Close"
import {useLogout} from "@/hooks/useLogout"

function BurgerMenu() {
   const [open, setOpen] = useState(false)

   const openMenu = () => setOpen(true)
   const closeMenu = () => setOpen(false)

   const {logout} = useLogout()

   return (
      <>
         <button onClick={openMenu} className="md:hidden p-4 rounded-lg bg-white text-blue">
            <MenuOutlinedIcon fontSize="large" />
         </button>
         <SwipeableDrawer open={open} onOpen={openMenu} onClose={closeMenu} anchor="left">
            <div className="h-full w-[90vw] bg-blue">
               <section className="flex items-center justify-between mt-[50px] ml-4 mr-8">
                  <button onClick={closeMenu} className="p-3 rounded-md bg-white text-blue">
                     <CloseIcon fontSize="large" />
                  </button>
                  <Link to="/perfil">
                     <AccountCircleIcon fontSize="large" className="text-white" />
                  </Link>
               </section>
               <section className="flex flex-col mt-8">
                  {menuData.map(({href, label}, i) => (
                     <Link
                        key={i}
                        to={href}
                        className="w-full text-blue border-b-[0.5px] border-blue bg-white text-center py-3 text-xl"
                     >
                        {label}
                     </Link>
                  ))}
               </section>
               <button
                  className="bg-gray-500 px-2 py-1 rounded-lg text-white flex self-end mx-auto text-sm mt-40"
                  onClick={logout}
               >
                  Cerrar sesión
               </button>
            </div>
         </SwipeableDrawer>
      </>
   )
}
