/* eslint-disable @typescript-eslint/no-explicit-any */
// axios
import axios from "axios"
// API_URL
import {API_URL} from "../constants"

interface User {
   email: string
   nombre: string
   telefono: string
   type: any
}

export async function AddUser(user: User, headers: any) {
   const userAdded = await axios.post(`${API_URL}/usuarios/usuario`, user, headers)
   return userAdded
}

export async function DeleteSolicitud(email: string) {
   await axios.delete(`${API_URL}/usuarios/solicitud/${email}`)
}

export async function CheckSolicitud(email: string, key: string) {
   const solicitud = await axios.get(`${API_URL}/usuarios/solicitud/${email}/${key}`)
   return solicitud
}

export async function AddTrabajador(
   solicitud: {
      email: string
      type: string
   },
   setError: React.Dispatch<React.SetStateAction<string | null>>,
) {
   const headers = {
      "Content-Type": "application/json",
   }
   const response = await axios.post(`${API_URL}/usuarios/solicitud`, solicitud, {
      headers,
      withCredentials: true,
   })
   // if there is a problem with the reponse
   if (response.status !== 200) {
      setError("Inserta correo y el tipo")
      return
   }

   return response.data.id
}
