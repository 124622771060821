// Home component page
// Rodrigo Nunez, May 2024
import React from "react"
import Page from "../../layouts/Page"
import SearchIcon from "@mui/icons-material/Search"
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage"
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail"
import PersonIcon from "@mui/icons-material/Person"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import {Link} from "react-router-dom"

export default function Home() {
   const iconSX = {
      width: 60,
      height: 60,
   }
   const linksData = [
      {
         href: "/inmuebles",
         label: "Buscador de inmuebles",
         icon: <SearchIcon sx={iconSX} />,
      },
      {
         href: "/condominios",
         label: "Buscador de condominios",
         icon: <HolidayVillageIcon sx={iconSX} />,
      },
      {
         href: "/usuarios",
         label: "Altas/bajas de usuarios",
         icon: <AlternateEmailIcon sx={iconSX} />,
      },
      {
         href: "/asociados",
         label: "Altas/bajas de asociados",
         icon: <PersonIcon sx={iconSX} />,
      },
      {
         href: "/temporadas",
         label: "Control de temporadas",
         icon: <CalendarMonthIcon sx={iconSX} />,
      },
   ]

   return (
      <Page className="py-8 px-4 md:px-10">
         <h1 className="font-bold text-3xl md:text-5xl text-center font-peignot flex flex-col items-center gap-4 drop-shadow-lg md:shadowed-text-black mx-8 md:mx-0 md:mt-8 ">
            <div className="flex">
               <p className="text-red">Casas</p> &nbsp;<p className="text-green">Acapulco</p>
               &nbsp;
               <p className="text-blue">Diamante</p>
            </div>
            <p>Socios</p>
         </h1>
         <section className="flex flex-col md:flex-row md:flex-wrap items-center justify-center gap-4 md:my-20 my-8">
            {linksData.map(({href, label, icon}, i) => (
               <Link
                  key={i}
                  to={href}
                  className="flex flex-col items-center justify-center gap-4 text-white bg-blue rounded-md drop-shadow-lg h-[200px] w-[200px] p-4"
               >
                  {icon}
                  <p className="text-center text-lg">{label}</p>
               </Link>
            ))}
         </section>
      </Page>
   )
}
