export const CADBLUE = "#00aeef"
export const CADGREEN = "#00b140"
export const CADGRAYTEXT = "#6A7280"

export const inmAndCondStyles = {
   mainDiv: "h-[88vh] overflow-y-scroll",
   loading: "text-center text-xl font-bold mt-20",
   secondaryDiv: "flex flex-col flex-wrap mt-10 items-center",
   searchForm: "flex items-center content-center w-[50%] space-x-4 mt-5",
   searchBtn: "border rounded bg-blue-600 text-white font-bold p-2",
   cardContainer: "grid self-center w-full gap-[20px]",
   paginationDiv: "my-8",
}

export const inmAndCondIndStyles = {
   disponibilidadBtn:
      "border rounded bg-green-600 font-bold text-sm text-white p-1 text-center mb-5",
   gridDivs: "border rounded shadow-lg mr-4 p-4 text-sm overflow-y-scroll h-40",
   h5Titles: "font-semibold mb-1 text-sm",
   iconColors: "text-green",
   caracteristicas: "flex flex-wrap space-x-3 text-sm mt-2",
}

export const grayFormStyle =
   "bg-gray-100 border border-gray-300 shadow-lg flex flex-col items-center p-10 w-[90vw] md:w-[50vw] md:max-w-[800px] rounded-md text-center gap-4 mb-20"

// environments
export const environments = [
   "Alberca Privada",
   "Jacuzzi Privado",
   "Terraza",
   "Roof Garden",
   "Cuarto de Servicio",
   "Bodega",
   "Jardín",
]

// house environments
export const houseEnvironments = [
   "Cancha de Padel",
   "Cancha de Tenis",
   "Sala de TV",
   "Salón de Juegos",
   "Estudio",
   "Salón de Usos múltiples",
   "Sauna",
   "Spa",
   "Palapa",
   "Asador",
]

// condo amenities
export const condoAmenities = [
   "Albercas",
   "Cancha de Tenis",
   "Cancha de Pádel",
   "Gimnasio",
   "Salón multi usos",
   "Ludoteca",
   "Spa",
   "Estética",
   "Restaurante",
   "Cine",
   "Salón de Jóvenes",
   "Snack Bar",
   "Elevador",
   "Seguridad 24/7",
   "Acceso a personas con movilidad reducida",
   "Estacionamiento de visitas",
   "Roof Garden",
   "Clínica",
]

// condo access to beach values
export const accessToBeachValues = ["Acceso Directo", "Acceso con Club de Playa", "Cercano al Mar"]

export const classifications = ["AAAA", "AAA", "AA", "A", "BBB", "BB", "B", "CCC", "CC", "C"]

export const furnishTypes = ["Amueblado", "Sin muebles", "Mobiliario a tratar"]

export const zones = [
   "Acapulco Dorado",
   "Punta Diamante",
   "Escénica (Brisas)",
   "Acapulco Playa Diamante",
   "Barra Vieja",
   "Playa Encantada",
]

export enum Status {
   FRACTIONAL = "Copropiedad/Fractional",
   RESTORING = "En reparación",
   VERIFIED = "Inmueble verificado",
   PRESALE = "Preventa",
   TO_RESTORE = "Para restaurar",
   NOT_UPDATED = "Sin actualizar (por Otis)",
}

// allEnvironemnts
export const allEnvironments = [...environments, ...houseEnvironments]

const LOCAL = "http://localhost:8080"
const DEV = "https://testserver.casasacapulcodiamante.com"
const PROD = "https://server.casasacapulcodiamante.com"

const env = process.env.REACT_APP_ENV
export const API_URL = env === "production" ? PROD : env === "development" ? DEV : LOCAL

export const CLIENTS_PROD = "https://casasacapulcodiamante.com"
export const OLDCAD_URL = "https://casasacapulcodiamante.mx"
export const PROD_URL = "https://socios.casasacapulcodiamante.com"

export const REDIRECT_URL = CLIENTS_PROD

/**
 * @description This is the data used for rendering the top section menu in the condos page.
 */
export const CONDO_SECTIONS = [
   {title: "Información", id: "condo-description"},
   {title: "Archivos", id: "condo-files"},
   {title: "Inmuebles", id: "condo-properties"},
   {title: "Imágenes", id: "condo-images"},
   {title: "Videos", id: "condo-videos"},
   {title: "Ubicación", id: "condo-location"},
]
