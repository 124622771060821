import React, {useState, useEffect} from "react"
import {useNavigate, useLocation} from "react-router-dom"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import {Alert} from "@mui/material"
import Page from "@/layouts/Page"
import {editAssociate} from "@/api/asociados"
import PersonIcon from "@mui/icons-material/Person"
import PhoneIcon from "@mui/icons-material/Phone"
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail"
import {grayFormStyle} from "@/constants"
import TextInput from "@/components/ui/TextInput"
import {AssociateTypes} from "@/types/Associates"
import InputLabel from "@mui/material/InputLabel"
import NativeSelect from "@mui/material/NativeSelect"

function EditAssociate() {
   const [error, setError] = useState<null | string>(null)
   const [loading, setLoading] = useState(false)
   const [name, setName] = useState("")
   const [number, setNumber] = useState<number | null>(null)
   const [email, setEmail] = useState("")
   const [id, setId] = useState("")
   const location = useLocation()
   const navigate = useNavigate()
   const [type, setType] = useState<AssociateTypes>(AssociateTypes.RUNNER)

   useEffect(() => {
      setLoading(true)
      const state: any = location.state
      setName(state.name)
      setNumber(state.number)
      setEmail(state.email)
      setType(state.type)
      setId(state.id)
      setLoading(false)
   }, [])

   const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault()
      setError(null)
      setLoading(true)
      if (name === "") {
         setError("El nombre no puede estar vacio")
         setLoading(false)
         return
      }
      if (number === 0 || number === null) {
         setError("El numero no puede estar vacio")
         setLoading(false)
         return
      }
      try {
         const associate = {name, number, email, type}
         await editAssociate(id, associate)
         setLoading(false)
         localStorage.removeItem("fetchedPartners")
         navigate("/asociados")
      } catch (error) {
         setError("Hubo un error al agregar el asociado")
         setLoading(false)
      }
   }

   return (
      <Page className="flex flex-col items-center">
         <ArrowBackIcon
            className="font-bold mb-7 cursor-pointer mt-5 fles self-start"
            onClick={() => navigate(-1)}
         />
         <h3 className="text-center text-2xl text-blue font-bold mt-5 mb-5">Editar asociado</h3>
         <form action="submit" onSubmit={handleSubmit} className={grayFormStyle}>
            <p>Edita los campos de asociado para actualizar la información del usuario.</p>
            {error && (
               <Alert severity="error" className="text-center text-2xl mb-5 w-[30%] mx-auto">
                  {error}
               </Alert>
            )}
            <TextInput
               icon={<PersonIcon />}
               value={name}
               type="text"
               required
               onChange={(e) => setName(e.target.value)}
               placeholder="Nombre completo *"
            />
            <TextInput
               icon={<PhoneIcon />}
               value={number ? number : ""}
               type="number"
               required
               onChange={(e) => setNumber(+e.target.value)}
               placeholder="Teléfono *"
            />
            <TextInput
               icon={<AlternateEmailIcon />}
               value={email}
               type="email"
               required
               onChange={(e) => setEmail(e.target.value)}
               placeholder="Correo electrónico *"
            />
            <div className="mx-auto w-full md:w-[80%] h-full mb-4">
               <InputLabel variant="standard" className="text-start mb-2 md:ml-12">
                  Tipo
               </InputLabel>
               <NativeSelect
                  className="w-full md:w-[80%] p-2"
                  defaultValue={AssociateTypes.OWNER}
                  onChange={(e) => setType(e.target.value as AssociateTypes)}
                  value={type}
               >
                  {Object.values(AssociateTypes).map((a) => (
                     <option key={a} value={a}>
                        {a}
                     </option>
                  ))}
               </NativeSelect>
            </div>
            <button
               className={`border rounded-md md:rounded-full bg-green hover:bg-gray-800 px-6 py-2 font-bold text-white ${
                  loading && "bg-blue"
               }`}
               type="submit"
               disabled={loading}
            >
               {loading ? "Cargando..." : "Guardar cambios"}
            </button>
            <button
               className="border rounded-md md:rounded-full bg-gray-500 hover:bg-gray-800 px-6 py-2 font-bold text-white"
               onClick={() => navigate(-1)}
            >
               Cancelar
            </button>
         </form>
      </Page>
   )
}

export default EditAssociate
