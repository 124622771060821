import React, {useState, useEffect} from "react"
import {API_URL} from "../../constants"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import DeleteIcon from "@mui/icons-material/Delete"
import AddIcon from "@mui/icons-material/Add"
import axios from "axios"
import dayjs from "dayjs"
import TemporadasForm from "../../components/calendar/TemporadasForm"
import Page from "../../layouts/Page"

function Temporadas() {
   const [loading, setLoading] = useState(false)
   const [temporadas, setTemporadas] = useState<any[]>([])
   const [showAddTemp, setShowAddTemp] = useState(false)
   const [disableDelete, setDisableDelete] = useState(false)

   const hideForm = () => {
      setShowAddTemp(false)
   }

   useEffect(() => {
      const fetchTemporadas = async () => {
         setLoading(true)
         // fetch temporadas
         const temporadas = await axios({
            method: "GET",
            url: `${API_URL}/temporadas`,
         })
         temporadas.data.data.sort((a: any, b: any) => {
            return b.initial._seconds - a.initial._seconds
         })
         setTemporadas(temporadas.data.data)
         setLoading(false)
      }

      fetchTemporadas()
   }, [])

   const fetchTemporadas = async () => {
      try {
         const temporadas = await axios({
            method: "GET",
            url: `${API_URL}/temporadas`,
         })
         temporadas.data.data.sort((a: any, b: any) => {
            return b.initial._seconds - a.initial._seconds
         })
         setTemporadas(temporadas.data.data)
         setDisableDelete(false)
      } catch (error) {
         console.log("Error de inmueble: " + error)
         setDisableDelete(false)
      }
   }

   // delete temporada
   const deleteTemp = async (id: string) => {
      try {
         setDisableDelete(true)
         await axios({
            method: "delete",
            url: `${API_URL}/temporadas/temporada/${id}`,
            withCredentials: true,
            headers: {
               "Content-Type": "application/json",
            },
         })
         fetchTemporadas()
      } catch (error) {
         console.log("Error de inmueble: " + error)
         setDisableDelete(false)
      }
   }

   return (
      <Page className="flex flex-col items-center py-12 gap-8">
         {showAddTemp ? (
            <TemporadasForm hideForm={hideForm} fetchTemporadas={fetchTemporadas} />
         ) : (
            <>
               <h1 className="text-blue text-3xl font-bold">Temporadas</h1>
               <button
                  onClick={() => setShowAddTemp(true)}
                  className="rounded-full bg-blue text-white shadow-lg px-6 py-1 flex gap-2 items-center"
               >
                  <AddIcon fontSize="large" />
                  Agregar temporada
               </button>
               <TableContainer
                  component={Paper}
                  className="max-w-[1200px] overflow-x-scroll"
                  sx={{
                     overflowX: "scroll",
                  }}
               >
                  <Table aria-label="simple table">
                     <TableHead>
                        <TableRow>
                           <TableCell align="left">Alta o Media</TableCell>
                           <TableCell align="left">Desde</TableCell>
                           <TableCell align="left">Hasta</TableCell>
                           <TableCell align="left">Eliminar</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody sx={{width: "100%"}}>
                        {temporadas?.map((date: any) => (
                           <TableRow
                              key={date.id}
                              sx={{"&:last-child td, &:last-child th": {border: 0}}}
                           >
                              <TableCell
                                 sx={{
                                    maxWidth: "120px",
                                    overflowWrap: "break-word",
                                 }}
                              >
                                 {date?.is_high ? "Alta" : "Media"}
                              </TableCell>
                              <TableCell align="left">
                                 {dayjs.unix(date.initial._seconds).format("DD-MM-YYYY")}
                              </TableCell>
                              <TableCell align="left">
                                 {dayjs.unix(date.end._seconds).format("DD-MM-YYYY")}
                              </TableCell>
                              <TableCell align="left">
                                 {!disableDelete && (
                                    <DeleteIcon
                                       className="text-red self-center cursor-pointer"
                                       onClick={() => deleteTemp(date.id)}
                                    />
                                 )}
                                 {disableDelete && (
                                    <DeleteIcon className="text-gray-400 self-center cursor-not-allowed" />
                                 )}
                              </TableCell>
                           </TableRow>
                        ))}
                     </TableBody>
                  </Table>
               </TableContainer>
            </>
         )}
      </Page>
   )
}

export default Temporadas
