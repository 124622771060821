import React, {useState, useEffect} from "react"
import {Modal} from "@mui/material"
import {sendResetPasswordEmail, verifyEmail} from "../../api/usuario"
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail"
import AuthPage, {GoToLogin} from "@/layouts/AuthPage"
import {Link} from "react-router-dom"
import CloseIcon from "@mui/icons-material/Close"
import TextInput from "@/components/ui/TextInput"

function ResetPassword() {
   const [inputEmail, setInputEmail] = useState("")
   const [debouncedEmail, setDebouncedEmail] = useState("")
   const [emailIsValid, setEmailIsValid] = useState(false)
   const [responseModal, setResponseModal] = useState(false)
   const [resetPasswordError, setResetPassworError] = useState(false)
   const [checkingEmail, setCheckingEmail] = useState(false)

   // funcion que envía correo para restablecer contraseña y abre modal de respuesta
   async function handleResetPassword() {
      const res = await sendResetPasswordEmail(debouncedEmail)
      if (res.success) {
         setResponseModal(true)
         setResetPassworError(false)
      } else {
         console.log("Error al enviar correo de restablecimiento de contraseña: " + res)
         setResponseModal(true)
         setResetPassworError(true)
      }
      setEmailIsValid(false)
   }

   // funcion que checa si el correo ya existe en auth o no es valido
   async function checkEmail(email: string) {
      setCheckingEmail(true)
      const res = await verifyEmail(email)
      if (res.success) {
         setEmailIsValid(true)
      } else {
         setEmailIsValid(false)
      }
      setCheckingEmail(false)
   }

   useEffect(() => {
      const timer = setTimeout(() => {
         setDebouncedEmail(inputEmail)
      }, 1000)
      return () => {
         clearTimeout(timer)
      }
   }, [inputEmail])

   useEffect(() => {
      if (debouncedEmail) {
         checkEmail(debouncedEmail)
      }
   }, [debouncedEmail])

   return (
      <AuthPage title="Restablecer contraseña">
         <p className="mb-2">
            Introduce el correo electrónico de tu cuenta para recibir un enlace de restablecimiento
            de contraseña.
         </p>
         <TextInput
            icon={<AlternateEmailIcon />}
            placeholder="Correo electrónico"
            type="email"
            required
            value={inputEmail}
            onChange={(e) => setInputEmail(e.target.value)}
         />
         {checkingEmail ? (
            <p className="animate-pulse text-xs mx-[20%]">Revisando existencia del correo...</p>
         ) : (
            !emailIsValid && (
               <p className=" text-xs md:mx-[20%]">
                  Si el correo no es válido, no se puede enviar enlace de restablecimiento de
                  contraseña.
               </p>
            )
         )}
         <button
            className={`${
               emailIsValid
                  ? "bg-green hover:bg-gray-800 shadow-md text-white"
                  : "border border-gray-400 text-gray-400"
            } rounded-lg p-[6px] px-4 block mx-auto`}
            disabled={!emailIsValid}
            onClick={handleResetPassword}
         >
            Restablecer contraseña
         </button>
         <GoToLogin />
         <Modal open={responseModal} onClose={() => setResponseModal(false)}>
            <div className="p-8 w-full md:max-w-[600px] md:w-[50vw] bg-white rounded-lg mx-auto  my-[40%] md:my-[20%] text-gray-500 text-center flex flex-col items-center gap-6 relative">
               <button className="absolute right-4 top-4" onClick={() => setResponseModal(false)}>
                  <CloseIcon fontSize="large" />
               </button>

               <h1
                  className={`text-3xl font-bold ${resetPasswordError ? "text-red" : "text-blue"}`}
               >
                  {resetPasswordError ? "Error" : "Correo enviado"}
               </h1>
               <p className="mx-8">
                  {resetPasswordError
                     ? "Error al enviar correo. Intente nuevamente o contacte al administrador."
                     : "¡El correo electrónico fue enviado correctamente! Revisa también tu carpeta de spam."}
               </p>
               <Link
                  to="/login"
                  onClick={() => {
                     setResponseModal(false)
                  }}
                  className="text-white relative w-[50%] bg-green rounded p-[6px] block mx-auto my-[20px] hover:border hover:border-gray-500 hover:bg-white hover:text-gray-500 shadow-lg"
               >
                  <p>Iniciar sesión</p>
               </Link>
            </div>
         </Modal>
      </AuthPage>
   )
}

export default ResetPassword
