/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios"
import {API_URL} from "../constants"

type stringOrNull = string | null

// get condominios
export const getCondominios = async (lastCond: stringOrNull, q: string, page: number) => {
   let res
   if (lastCond) {
      res = await axios.get(`${API_URL}/condominios?lastCondominio=${lastCond}`)
      return res
   }
   res = await axios.get(`${API_URL}/condominios?q=${q}&searchPage=${page}`)
   return res
}

type pathName = string

// get condominio by id
export const getCondominioById = async (pathName: pathName) => {
   const res = await axios.get(`${API_URL}/condominios/condominio/${pathName}`)
   return res
}

// get all conds
export const getAllConds = async () => {
   const res = await axios.get(`${API_URL}/condominios/allConds`)
   return res
}

// get inmuebles conds
export const getInmueblesCond = async (condId: string) => {
   const inmueblesRes = await axios.get(`${API_URL}/condominios/inmuebles/${condId}`)
   return inmueblesRes
}

// post condominio
export const postCondominio = async (condominio: any) => {
   const resCond = await axios({
      method: "post",
      url: `${API_URL}/condominios/condominio`,
      data: condominio,
      withCredentials: true,
      headers: {
         "Content-Type": "application/json",
      },
   })
   return resCond.data.condominio
}

// put condominio images
export const putCondominioImages = async (FormData: any, id: string) => {
   const resPut = await axios({
      method: "PUT",
      url: `${API_URL}/condominios/condominio/images?id=${id}`,
      data: FormData,
      withCredentials: true,
      headers: {
         "Content-Type": "multipart/form-data; charset=utf-8",
      },
   })
   return resPut
}

// update condominio values
export const editCondominio = async (condominioUpdated: any, id: string) => {
   await axios({
      method: "put",
      url: `${API_URL}/condominios/condominio/data/${id}`,
      data: condominioUpdated,
      withCredentials: true,
      headers: {
         "Content-Type": "application/json",
      },
   })
}

// order images of the condominio
export const orderImages = async (id: string, images: any, condominio: any) => {
   await axios({
      method: "PUT",
      url: `${API_URL}/condominios/condominio/orderImages`,
      data: {id: id, images: images, condominio: condominio},
      withCredentials: true,
      headers: {
         "Content-Type": "application/json",
      },
   })
}

// order sections
export const orderSections = async (id: string, images: any, categoriesUpdated: any) => {
   await axios({
      method: "put",
      url: `${API_URL}/condominios/updateSections`,
      data: {
         id,
         orderedSections: categoriesUpdated,
         images,
      },
      withCredentials: true,
      headers: {
         "Content-Type": "application/json",
      },
   })
}

// main image changes
export const mainImageChanges = async (id: string, formData: FormData) => {
   await axios({
      method: "put",
      url: `${API_URL}/condominios/updateMainImage/${id}`,
      data: formData,
      withCredentials: true,
      headers: {
         "Content-Type": "multipart/form-data; charset=utf-8",
      },
   })
}

// add images
export const addImages = async (id: string, formData: FormData) => {
   await axios({
      method: "put",
      url: `${API_URL}/condominios/addImages/${id}`,
      data: formData,
      withCredentials: true,
      headers: {
         "Content-Type": "multipart/form-data; charset=utf-8",
      },
   })
}

// delete images
export const deleteImages = async (id: string, deletedImages: string[]) => {
   await axios({
      method: "delete",
      url: `${API_URL}/condominios/deleteImages/${id}`,
      data: {
         deletedImages,
      },
      withCredentials: true,
      headers: {
         "Content-Type": "application/json",
      },
   })
}

// delete section images
export const deleteSectionImages = async (
   id: string,
   categories: any,
   categoriesUpdated: any,
   email: string,
   images: any,
) => {
   const res = await axios({
      method: "delete",
      url: `${API_URL}/condominios/categoryImages`,
      data: {
         id,
         categories,
         categoriesUpdated,
         images,
      },
      withCredentials: true,
      headers: {
         "Content-Type": "application/json",
      },
   })
   return res.data.images
}

// delete main image
export const deleteMainImage = async (id: string, email: string, mainImage: string) => {
   await axios({
      method: "delete",
      url: `${API_URL}/condominios/mainImageDelete/${id}`,
      data: {
         mainImage,
      },
      withCredentials: true,
      headers: {
         "Content-Type": "application/json",
      },
   })
}

// upload new Images
export const uploadNewImages = async (id: string, email: string, images: any) => {
   const res = await axios({
      method: "put",
      url: `${API_URL}/condominios/uploadNewImages/${id}`,
      data: images,
      withCredentials: true,
      headers: {
         "Content-Type": "multipart/form-data; charset=utf-8",
      },
   })
   return res.data.filePaths
}
