import React, {useEffect, useState} from "react"
import {useLocation, useNavigate} from "react-router-dom"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"
import DataRow from "@/components/ui/DataRow"
import Page from "@/layouts/Page"
import {Box, TextField} from "@mui/material"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select, {SelectChangeEvent} from "@mui/material/Select"
import Chip from "@mui/material/Chip"
import OutlinedInput from "@mui/material/OutlinedInput"
import Alert from "@mui/material/Alert"
import {getAllConds} from "@/api/condominio"
import {useAuthContext} from "@/hooks/useAuthContext"
import {editInmueble, checkNameTaken} from "@/api/inmueble"
import {getAssociates} from "@/api/asociados"
import {
   environments as envs,
   allEnvironments as allEnvs,
   furnishTypes,
   zones,
   Status,
} from "@/constants"
import DeletePropertyModal from "./DeleteModal"
import BooleanToggle from "@/components/properties/BooleanToggle"
import {PropertyType} from "@/types/Property"

interface Condominio {
   value: string
   label: string
}

interface Partners {
   value: string
   label: string
}

function EditInmueble() {
   const location = useLocation()
   const navigate = useNavigate()
   const {user} = useAuthContext()
   const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
   const [error, setError] = useState<boolean | string>(false)
   const [condominios, setCondominios] = useState<Condominio[]>()
   // Inmueble
   const [prevName, setPrevName] = useState<null | string>(null)
   const [name, setName] = useState<null | string>(null)
   const [status, setStatus] = useState<null | Status>(null)
   const [desc, setDesc] = useState<null | string>(null)
   const [desc2, setDesc2] = useState<null | string>(null)
   const [urlCAD, setUrlCad] = useState<null | string>(null)
   const [condo, setCondo] = useState<null | string>(null)
   const [type, setType] = useState<null | PropertyType>(null)
   const [capacity, setCapacity] = useState<null | number>(null)
   const [mts, setMts] = useState<null | number>(null)
   const [mtsContruccion, setMtsContruccion] = useState<null | number>(null)
   const [floor, setFloor] = useState<null | number>(null)
   const [levels, setLevels] = useState<null | number>(null)
   const [rooms, setRooms] = useState<null | number>(null)
   const [bathrooms, setBathrooms] = useState<null | number>(null)
   const [parkingLots, setParkingLots] = useState<null | number>(null)
   const [mensual_anual, setMensualAnual] = useState<null | number>(null)
   const [mensual_regular, setMensualRegular] = useState<null | number>(null)
   const [noche_alta, setNocheAlta] = useState<null | number>(null)
   const [noche_media, setNocheMedia] = useState<null | number>(null)
   const [noche_regular, setNocheRegular] = useState<null | number>(null)
   const [costoVenta, setCostoVenta] = useState<null | number>(null)
   const [seaview, setSeaview] = useState<string>("")
   const [furnished, setFurnished] = useState<string>("")
   const [furnishType, setFurnishType] = useState<string>("")
   const [pricePerMeter, setPricePerMeter] = useState<null | number>(null)
   const [maintenance, setMaintenace] = useState<null | number>(null)
   const [zone, setZone] = useState<string | null>("")
   const [environments, setEnvironments] = useState<string[]>([])
   const [outingToTheBeach, setOutingToTheBeach] = useState<string>("")
   const [houseAntiquity, setHouseAntiquity] = useState<null | string>(null)
   const [available, setAvailable] = useState<boolean | null>(null)
   const [suspended, setSuspended] = useState<boolean | null>(null)
   const [mainImage, setMainImage] = useState<string>("")
   const [images, setImages] = useState<any>()
   const [orderedSections, setOrderedSections] = useState<string[]>()
   const [notes, setNotes] = useState<null | string>(null)
   const [privateNotes, setPrivateNotes] = useState<null | string>(null)
   const [partners, setPartners] = useState<string[]>([])
   const [partnersFetched, setPartnersFetched] = useState<Partners[]>([])
   const [address, setAddress] = useState<null | string>(null)
   const [mapsUrl, setMapsUrl] = useState<string>("")
   const [mapsShareUrl, setMapsShareUrl] = useState<string>("")
   const [calendar, setCalendar] = useState<any>(null)
   const [verifiedAt, setVerifiedAt] = useState<null | string>(null)
   const [petFriendly, setPetFriendly] = useState<string>("")
   // videos state
   const [videos, setVideos] = useState<string>("")
   const [videosList, setVideosList] = useState<string[]>([])
   // loading state
   const [loading, setLoading] = useState<boolean>(false)
   const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
   const [condoName, setCondoName] = useState<string>("")
   const [saleComission, setSaleComission] = useState<number | null>(null)
   const id = location.pathname.split("/editInmueble/")[1]

   useEffect(() => {
      setLoading(true)
      const fetchCondominios = async () => {
         const res = await getAllConds()
         const condOptions: Condominio[] = []
         res.data.data.forEach((cond: any) => {
            condOptions.push({value: cond.id, label: cond.name})
         })
         setCondominios(condOptions)
         setLoading(false)
      }
      const fetchPartners = async () => {
         const res = await getAssociates()
         const associates: Partners[] = []
         res.forEach((ass: any) => {
            associates.push({value: ass.id, label: ass.name})
         })
         setPartnersFetched(associates)
      }
      const state: any = location.state
      const inmueble: any = state.inmueble
      if (!inmueble) {
         navigate("/inmuebles")
         return
      }
      setName(inmueble.name)
      setPrevName(inmueble.name)
      setZone(inmueble.zone || "")
      setDesc(inmueble.desc)
      setDesc2(inmueble.desc2)
      setUrlCad(inmueble.urlCAD)
      setCalendar(inmueble.calendar || null)
      setCondo(inmueble.condo)
      setType(inmueble.type)
      setCapacity(inmueble.capacity)
      setMts(inmueble.mts)
      setVerifiedAt(inmueble.verifiedAt || null)
      setMtsContruccion(inmueble.mtsConstruction)
      setFloor(inmueble.floor)
      setLevels(inmueble.levels)
      setRooms(inmueble.rooms)
      setBathrooms(inmueble.bathrooms)
      setParkingLots(inmueble.parkingLots)
      setMensualAnual(inmueble.costos?.mensual_anual || null)
      setMensualRegular(inmueble.costos?.mensual_regular || null)
      setNocheAlta(inmueble.costos?.noche_alta || null)
      setNocheMedia(inmueble.costos?.noche_media || null)
      setNocheRegular(inmueble.costos?.noche_regular || null)
      setCostoVenta(inmueble?.costoVenta || null)
      setSeaview(inmueble.seaview === true ? "true" : "false")
      setFurnished(inmueble.furnished === true ? "true" : "false")
      setPricePerMeter(inmueble.pricePerMeter)
      setMaintenace(inmueble.maintenance)
      setStatus(inmueble.status)
      const envs: string[] = []
      !inmueble.environments ? null : envs.push(...inmueble.environments)
      !inmueble.houseEnvironments ? null : envs.push(...inmueble.houseEnvironments)
      setEnvironments(envs)
      setOutingToTheBeach(inmueble.outing_to_the_beach === true ? "true" : "false")
      setHouseAntiquity(inmueble.house_antiquity ? inmueble.house_antiquity : null)
      setVideosList(!inmueble.videos ? [] : inmueble.videos)
      setMainImage(inmueble.mainImage)
      setImages(inmueble.images)
      setOrderedSections(inmueble.orderedSections)
      if (inmueble.available === undefined) {
         setLoading(false)
         return
      }
      setAvailable(inmueble.available)
      setSuspended(inmueble.suspended)
      setNotes(inmueble.notes)
      setPartners(inmueble.partners || [])
      setAddress(inmueble.address)
      setFurnishType(inmueble.furnishType)
      setPrivateNotes(inmueble.privateNotes)
      setMapsUrl(inmueble.mapsUrl || "")
      setMapsShareUrl(inmueble.mapsShareUrl || "")
      setPetFriendly(
         inmueble.petFriendly !== undefined ? (inmueble.petFriendly ? "true" : "false") : "",
      )
      setCondoName(inmueble.condoName || "")
      setSaleComission(inmueble.saleComission || null)
      fetchPartners()
      fetchCondominios()
   }, [])

   /**
    * Helper booleans to determine type of property
    */
   const isHouse = type === PropertyType.HOUSE
   const isApartment = type === PropertyType.APARTMENT
   const isLand = type === PropertyType.LAND

   // in order to update the enviroment state, which is an string array
   const handleChangeEnvironments = (event: SelectChangeEvent<typeof environments>) => {
      const {
         target: {value},
      } = event
      setEnvironments(
         // On autofill we get a stringified value.
         typeof value === "string" ? value.split(",") : value,
      )
   }

   const handleChangePartners = (event: SelectChangeEvent<typeof partners>) => {
      const {
         target: {value},
      } = event
      setPartners(
         // On autofill we get a stringified value.
         typeof value === "string" ? value.split(",") : value,
      )
   }

   // everytime a user adds a video, we add it to the videosList state
   const handleVideoChange = () => {
      setError(false)
      // if no video was added then an error is set
      if (videos === "") {
         setError("Ingresa el URL del video")
         return
      }
      // if the video list already exists we just add the new video to it
      if (videosList) {
         setVideosList((prevVideosList) => [...prevVideosList, videos.trim()])
      }
      // if videosList is empty, we add the video
      if (!videosList) {
         setVideosList([videos.trim()])
      }
      setVideos("")
   }

   // everytime the user deletes a video, we remove it from the videosList state
   const handleVideoDelete = (index: number) => {
      const videosUpdated = videosList.filter((video, i) => i !== index)
      setVideosList(videosUpdated)
   }

   const handleSubmit = async () => {
      setLoadingSubmit(true)
      setError(false)
      // check for every required field to be filled
      // if not an error is set
      if (!name || name === "") {
         setLoadingSubmit(false)
         setError("Elija un nombre")
         return
      }
      if (name !== prevName) {
         const nameTaken = await checkNameTaken(name)
         if (nameTaken) {
            setLoadingSubmit(false)
            setError(
               "El nombre de este inmueble ya está tomado. Favor de especificar otro nombre o contactar al administrador para notificar de un problema.",
            )
            return
         }
      }
      if (isApartment && (!condo || condo === "")) {
         setLoading(false)
         setError("Elija un condominio")
         return
      }
      if (!seaview || seaview === "") {
         setLoadingSubmit(false)
         setError("Elija si tiene vista al mar")
         return
      }
      if (!condo && zone === "") {
         setLoadingSubmit(false)
         setError("Elija una zona porque no hay condominio asignado")
         return
      }
      if (isHouse && mapsShareUrl === "") {
         setLoadingSubmit(false)
         setError("Como es casa, ingrese la URL de Google Maps para compartir")
         return
      }
      // populate environment arrays
      const envsUp: string[] = []
      const houseEnvsUp: string[] = []
      if (isHouse) {
         for (let i = 0; i < environments.length; i++) {
            envs.includes(environments[i])
               ? envsUp.push(environments[i])
               : houseEnvsUp.push(environments[i])
         }
      }
      const inmuebleUpdated: any = {
         id,
         name,
         status,
         desc: desc === "" ? null : desc?.trim(),
         desc2: desc2 === "" ? null : desc2?.trim(),
         urlCAD: urlCAD === "" ? null : urlCAD,
         condo: condo,
         type,
         capacity: capacity === 0 ? null : capacity,
         mts: mts === 0 ? null : mts,
         mtsConstruction: mtsContruccion === 0 ? null : mtsContruccion,
         floor: floor === 0 || isHouse ? null : floor,
         levels: levels === 0 ? null : levels,
         rooms: rooms === 0 ? null : rooms,
         bathrooms: bathrooms === 0 ? null : bathrooms,
         parkingLots: parkingLots === 0 ? null : parkingLots,
         costos: {
            mensual_anual: mensual_anual === 0 ? null : mensual_anual,
            mensual_regular: mensual_regular === 0 ? null : mensual_regular,
            noche_alta: noche_alta === 0 ? null : noche_alta,
            noche_media: noche_media === 0 ? null : noche_media,
            noche_regular: noche_regular === 0 ? null : noche_regular,
         },
         costoVenta: costoVenta === 0 ? null : costoVenta,
         videos: videosList.length <= 0 ? null : videosList,
         seaview: seaview === "true" ? true : false,
         furnished: isLand ? null : furnished === "true" ? true : false,
         pricePerMeter:
            mtsContruccion === 0 || costoVenta === 0 || !mtsContruccion || !costoVenta
               ? null
               : costoVenta / mtsContruccion,
         maintenance: maintenance === 0 ? null : maintenance,
         environments: !isHouse
            ? environments.length <= 0
               ? null
               : environments
            : envsUp.length <= 0
            ? null
            : envsUp,
         outing_to_the_beach: !isHouse ? null : outingToTheBeach === "true" ? true : false,
         house_environments: !isHouse ? null : houseEnvsUp.length <= 0 ? null : houseEnvsUp,
         house_antiquity: houseAntiquity === "" || !isHouse ? null : houseAntiquity,
         available: available,
         suspended: suspended,
         mainImage: mainImage,
         images: images,
         orderedSections: orderedSections,
         verifiedAt: verifiedAt,
         calendar: calendar,
         notes: notes === "" ? null : notes,
         privateNotes: privateNotes === "" ? null : privateNotes,
         partners: partners.length <= 0 ? null : partners,
         address: address === "" ? null : address,
         furnishType: furnishType === "" ? null : furnishType,
         mapsUrl: mapsUrl === "" ? null : mapsUrl,
         mapsShareUrl: mapsShareUrl === "" ? null : mapsShareUrl,
         zone: zone === "" ? null : zone,
         forSale: costoVenta ? true : false,
         // For rent is set after costos is parsed
         petFriendly: petFriendly === "" ? null : petFriendly === "true" ? true : false,
         condoName: condoName === "" ? null : condoName,
         saleComission: saleComission ? saleComission : null,
      }

      const inmuebleFields = Object.keys(inmuebleUpdated)
      for (const key in inmuebleFields) {
         if (
            (inmuebleUpdated[inmuebleFields[key]] === null ||
               inmuebleUpdated[inmuebleFields[key]] === undefined) &&
            inmuebleFields[key] !== "mainImage"
         ) {
            delete inmuebleUpdated[inmuebleFields[key]]
         }
      }
      const costosFields = Object.keys(inmuebleUpdated.costos)
      for (const key in costosFields) {
         if (
            inmuebleUpdated.costos[costosFields[key]] === null ||
            inmuebleUpdated.costos[costosFields[key]] === undefined
         ) {
            delete inmuebleUpdated.costos[costosFields[key]]
         }
      }
      const newCostos = Object.keys(inmuebleUpdated.costos)
      if (newCostos.length <= 0) {
         delete inmuebleUpdated.costos
      }

      // Set updated property's forRent value
      if (inmuebleUpdated.costos) {
         inmuebleUpdated.forRent = true
      } else {
         inmuebleUpdated.forRent = false
      }

      try {
         await editInmueble(inmuebleUpdated)
         navigate(`/inmuebles/${id}`)
         localStorage.removeItem("properties")
         setLoadingSubmit(false)
      } catch (error) {
         setLoadingSubmit(false)
         setError(
            error.response.data.errors[0].msg
               ? error.response.data.errors[0].msg
               : "Error al editar el inmueble",
         )
         console.log(error)
      }
   }

   return !loading ? (
      <Page>
         {/* if clicked you are returned to the latest page */}
         <header>
            <ArrowBackIcon
               className="font-bold mb-7 cursor-pointer mt-5"
               onClick={() => navigate(-1)}
            />
         </header>
         {/* in this div all our inputs are shown */}
         <div className="mb-5 flex flex-col max-w-[1600px] mx-auto px-4 md:px-[15%] gap-4">
            {/*
         if the showImageForm state is false, then the normal fields are displayed, 
         if not the image fields are displayed */}
            {/* title */}
            <h3 className="text-center text-2xl text-blue-600 font-bold mt-5 mb-5">
               Editar: {name}
            </h3>
            {/* error alert */}
            {error && (
               <Alert severity="error" className="text-center text-2xl mb-5 w-[30%] mx-auto">
                  {error}
               </Alert>
            )}
            <h3 className="text-blue mb-2">Información general</h3>
            <DataRow>
               <TextField
                  size="small"
                  label="Nombre de propiedad *"
                  fullWidth
                  value={name ? name : ""}
                  onChange={(e) => setName(e.target.value)}
               />
               <TextField
                  size="small"
                  label="URL CAD"
                  fullWidth
                  value={urlCAD ? urlCAD : ""}
                  onChange={(e) => setUrlCad(e.target.value)}
               />
            </DataRow>
            <DataRow>
               <BooleanToggle
                  label={
                     available
                        ? "Disponible"
                        : available === undefined
                        ? "Sin actualizar"
                        : "No disponible"
                  }
                  onPress={() => setAvailable(available === true ? false : true)}
                  condition={available === true}
               />
               <BooleanToggle
                  label={
                     suspended
                        ? "Suspendido"
                        : suspended === undefined
                        ? "Sin actualizar"
                        : "Sin suspender"
                  }
                  onPress={
                     suspended === true ? () => setSuspended(false) : () => setSuspended(true)
                  }
                  condition={suspended === false}
               />
               <Select
                  displayEmpty
                  value={status}
                  onChange={(e) => setStatus(e.target.value as Status)}
                  className="h-[38px]"
                  disabled={isLand}
               >
                  {Object.values(Status).map((s, i) => (
                     <MenuItem value={s} key={i}>
                        {s}
                     </MenuItem>
                  ))}
               </Select>
            </DataRow>
            <DataRow>
               <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                  <InputLabel id="demo-controlled-open-select-label">
                     Tipo de propiedad *
                  </InputLabel>
                  <Select
                     displayEmpty
                     labelId="demo-controlled-open-select-label"
                     id="demo-controlled-open-select"
                     label="Tipo de propiedad *"
                     value={type}
                     disabled
                  >
                     {Object.values(PropertyType).map((t, i) => (
                        <MenuItem value={t} key={i}>
                           {t}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
               {/* condominio */}
               <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                  <InputLabel id="demo-controlled-open-select-label">Condominio</InputLabel>
                  <Select
                     displayEmpty
                     labelId="demo-controlled-open-select-label"
                     id="demo-controlled-open-select"
                     label="Condominio"
                     value={condo ? condo : ""}
                     onChange={(e) => {
                        setCondo(e.target.value)
                        setZone("")
                     }}
                  >
                     {condominios?.map((cond) => (
                        <MenuItem key={cond.value} value={cond.value}>
                           {cond.label}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
               {/* rooms */}
               <TextField
                  size="small"
                  label="Recámaras *"
                  id="outlined-size-small"
                  fullWidth
                  sx={{margin: "0px 2%"}}
                  value={rooms ? rooms : ""}
                  onChange={(e) => setRooms(+e.target.value)}
                  disabled={isLand}
                  inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
               />
               {/* bathrooms */}
               <TextField
                  size="small"
                  label="Baños *"
                  id="outlined-size-small"
                  disabled={isLand}
                  fullWidth
                  sx={{margin: "0px 2%"}}
                  value={bathrooms ? bathrooms : ""}
                  onChange={(e) => setBathrooms(+e.target.value)}
                  inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
               />
               {/* parkingLots */}
               <TextField
                  size="small"
                  label="Cajones de estacionamiento"
                  id="outlined-size-small"
                  disabled={isLand}
                  fullWidth
                  sx={{margin: "0px 2%"}}
                  value={parkingLots ? parkingLots : ""}
                  onChange={(e) => setParkingLots(+e.target.value)}
                  inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
               />
            </DataRow>
            <DataRow>
               <TextField
                  size="small"
                  label="Niveles"
                  fullWidth
                  disabled={isLand}
                  value={levels ? levels : ""}
                  onChange={(e) => setLevels(+e.target.value)}
                  inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
               />
               <>
                  {isApartment && (
                     <TextField
                        size="small"
                        label="Piso"
                        disabled={isLand}
                        fullWidth
                        value={floor ? floor : ""}
                        onChange={(e) => setFloor(+e.target.value)}
                        inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                     />
                  )}
               </>
               <TextField
                  size="small"
                  label="Superficie terreno Mts²"
                  fullWidth
                  value={mts ? mts : ""}
                  onChange={(e) => setMts(+e.target.value)}
                  inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                  disabled={isApartment ? true : false}
               />
               <TextField
                  size="small"
                  label="Superficie construcción Mts²"
                  fullWidth
                  disabled={isLand}
                  value={mtsContruccion ? mtsContruccion : ""}
                  onChange={(e) => setMtsContruccion(+e.target.value)}
                  inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
               />
            </DataRow>
            <DataRow>
               <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                  <InputLabel id="demo-controlled-open-select-label">Zona</InputLabel>
                  <Select
                     labelId="demo-controlled-open-select-label"
                     id="demo-controlled-open-select"
                     label="Zona"
                     value={zone}
                     disabled={condo ? true : false}
                     onChange={(e) => setZone(e.target.value)}
                  >
                     {zones.map((z: string) => (
                        <MenuItem value={z} key={z}>
                           {z}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
               <TextField
                  size="small"
                  label="Dirección"
                  fullWidth
                  value={address ? address : ""}
                  onChange={(e) => setAddress(e.target.value)}
               />
            </DataRow>
            <DataRow>
               <TextField
                  size="small"
                  label="Google Maps URL para EMBED"
                  id="outlined-size-small"
                  fullWidth
                  sx={{margin: "0px 2%"}}
                  value={mapsUrl}
                  onChange={(e) => setMapsUrl(e.target.value)}
               />
               <TextField
                  size="small"
                  label="Google Maps URL para Compartir"
                  id="outlined-size-small"
                  fullWidth
                  sx={{margin: "0px 2%"}}
                  value={mapsShareUrl}
                  onChange={(e) => setMapsShareUrl(e.target.value)}
               />
            </DataRow>
            <FormControl size="small" sx={{margin: "0px 2%", width: "100%"}}>
               <InputLabel id="demo-multiple-chip-label">Asociados</InputLabel>
               <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={partners}
                  onChange={handleChangePartners}
                  input={<OutlinedInput id="select-multiple-chip" label="Asociados" />}
                  renderValue={(selected) => (
                     <Box sx={{display: "flex", flexWrap: "wrap", gap: 0.5}}>
                        {selected?.map((value) => {
                           const partner: any = partnersFetched.find(
                              (partner) => partner?.value === value,
                           )
                           return <Chip key={partner?.value} label={partner?.label} />
                        })}
                     </Box>
                  )}
               >
                  {partnersFetched.map((associate) => (
                     <MenuItem key={associate.value} value={associate.value}>
                        {associate.label}
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>
            <h3>Ambientes y otros detalles</h3>
            <DataRow>
               <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                  <InputLabel id="demo-controlled-open-select-label">Vista al mar *</InputLabel>
                  <Select
                     displayEmpty
                     labelId="demo-controlled-open-select-label"
                     id="demo-controlled-open-select"
                     label="Vista al mar *"
                     value={seaview}
                     onChange={(e) => setSeaview(e.target.value)}
                  >
                     <MenuItem value={"true"}>Si</MenuItem>
                     <MenuItem value={"false"}>No</MenuItem>
                  </Select>
               </FormControl>
               <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                  <InputLabel id="demo-controlled-open-select-label">Amueblado *</InputLabel>
                  <Select
                     disabled={isLand}
                     displayEmpty
                     labelId="demo-controlled-open-select-label"
                     id="demo-controlled-open-select"
                     label="Amueblado *"
                     value={furnished}
                     onChange={(e) => {
                        const value = e.target.value
                        if (value === "true") {
                           setFurnished(value)
                           setFurnishType("Amueblado")
                        } else {
                           setFurnished(value)
                           setFurnishType("Sin muebles")
                        }
                     }}
                  >
                     <MenuItem value={"true"}>Si</MenuItem>
                     <MenuItem value={"false"}>No</MenuItem>
                  </Select>
               </FormControl>
               {/* type of furnish */}
               <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                  <InputLabel id="demo-controlled-open-select-label">Tipo de amueblado</InputLabel>
                  <Select
                     displayEmpty
                     labelId="demo-controlled-open-select-label"
                     disabled={isLand}
                     id="demo-controlled-open-select"
                     label="Tipo de amueblado"
                     value={furnishType}
                     onChange={(e) => {
                        const value = e.target.value
                        if (value === "Amueblado" || value === "Mobiliario a tratar") {
                           setFurnished("true")
                           setFurnishType(value)
                        } else {
                           setFurnished("false")
                           setFurnishType(value)
                        }
                     }}
                  >
                     {furnishTypes.map((type: string) => (
                        <MenuItem value={type} key={type}>
                           {type}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
            </DataRow>
            {/* Ambientes */}
            <FormControl size="small" sx={{margin: "0px 2%", width: "100%"}}>
               <InputLabel id="demo-multiple-chip-label">Ambientes de la propiedad *</InputLabel>
               <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  disabled={isLand}
                  value={environments}
                  onChange={handleChangeEnvironments}
                  label="Ambientes de la propiedad *"
                  renderValue={(selected) => (
                     <Box sx={{display: "flex", flexWrap: "wrap", gap: 0.5}}>
                        {selected.map((value) => (
                           <Chip key={value} label={value} />
                        ))}
                     </Box>
                  )}
               >
                  {isHouse
                     ? allEnvs.map((env) => (
                          <MenuItem key={env} value={env}>
                             {env}
                          </MenuItem>
                       ))
                     : envs.map((env) => (
                          <MenuItem key={env} value={env}>
                             {env}
                          </MenuItem>
                       ))}
               </Select>
            </FormControl>
            {isHouse && (
               <DataRow>
                  <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                     <InputLabel id="demo-controlled-open-select-label">
                        Salida a la playa
                     </InputLabel>
                     <Select
                        displayEmpty
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        label="Salida a la playa"
                        value={outingToTheBeach}
                        onChange={(e) => setOutingToTheBeach(e.target.value)}
                     >
                        <MenuItem value={"true"}>Si</MenuItem>
                        <MenuItem value={"false"}>No</MenuItem>
                     </Select>
                  </FormControl>

                  <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                     <InputLabel id="demo-controlled-open-select-label">
                        Antigüedad de la casa
                     </InputLabel>
                     <Select
                        displayEmpty
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        label="Antiguedad de la casa"
                        value={houseAntiquity ? houseAntiquity : ""}
                        onChange={(e) => setHouseAntiquity(e.target.value)}
                     >
                        <MenuItem value={"En construcción"}>En construcción</MenuItem>
                        <MenuItem value={"A estrenar"}>A estrenar</MenuItem>
                        <MenuItem value={"Hasta 5 años"}>Hasta 5 años</MenuItem>
                        <MenuItem value={"Hasta 10 años"}>Hasta 10 años</MenuItem>
                        <MenuItem value={"Hasta 20 años"}>Hasta 20 años</MenuItem>
                        <MenuItem value={"Hasta 50 años"}>Hasta 50 años</MenuItem>
                     </Select>
                  </FormControl>
               </DataRow>
            )}
            <h3>Información de Venta</h3>
            <DataRow>
               {/* costoVenta */}
               <TextField
                  size="small"
                  label="Precio de venta MXN"
                  id="outlined-size-small"
                  fullWidth
                  sx={{margin: "0px 2%"}}
                  value={costoVenta ? costoVenta : ""}
                  onChange={(e) => setCostoVenta(+e.target.value)}
                  inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
               />
               {/* pricePerMeter */}
               <TextField
                  size="small"
                  label="Precio por metro cuadrado"
                  id="outlined-size-small"
                  fullWidth
                  sx={{margin: "0px 2%"}}
                  value={
                     costoVenta && mtsContruccion ? (costoVenta / mtsContruccion).toFixed(2) : ""
                  }
                  inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                  disabled
               />
               {/* maintenance */}
               <TextField
                  size="small"
                  label="Mantenimiento"
                  id="outlined-size-small"
                  fullWidth
                  sx={{margin: "0px 2%"}}
                  value={maintenance ? maintenance : ""}
                  onChange={(e) => setMaintenace(+e.target.value)}
                  inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
               />
            </DataRow>
            <FormControl size="small">
               <InputLabel>Comisión de Venta Pactada (%)</InputLabel>
               <Select
                  displayEmpty
                  label="Comisión de Venta Pactada (%)"
                  value={saleComission}
                  onChange={(e) => setSaleComission(e.target.value as number)}
               >
                  {Array.from({length: 19}, (_, i) => i * 0.5 + 1).map((i) => (
                     <MenuItem key={i} value={i}>
                        {i}%
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>
            <h3>Información de Renta</h3>
            <DataRow>
               {/* precio noche regular */}
               <TextField
                  size="small"
                  label="MXN Renta/noche temporada regular"
                  id="outlined-size-small"
                  fullWidth
                  sx={{margin: "0px 2%"}}
                  disabled={isLand}
                  value={noche_regular ? noche_regular : ""}
                  onChange={(e) => setNocheRegular(+e.target.value)}
                  inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
               />
               {/* precio noche media */}
               <TextField
                  size="small"
                  label="MXN Renta/noche temporada media"
                  id="outlined-size-small"
                  fullWidth
                  sx={{margin: "0px 2%"}}
                  disabled={isLand}
                  value={noche_media ? noche_media : ""}
                  onChange={(e) => setNocheMedia(+e.target.value)}
                  inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
               />
               {/* precio noche alta */}
               <TextField
                  size="small"
                  label="MXN Renta/noche temporada alta"
                  id="outlined-size-small"
                  fullWidth
                  disabled={isLand}
                  sx={{margin: "0px 2%"}}
                  value={noche_alta ? noche_alta : ""}
                  onChange={(e) => setNocheAlta(+e.target.value)}
                  inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
               />
            </DataRow>
            <DataRow>
               {/* precio mensual regular */}
               <TextField
                  size="small"
                  label="MXN Renta/mes contrato regular"
                  id="outlined-size-small"
                  fullWidth
                  sx={{margin: "0px 2%"}}
                  value={mensual_regular ? mensual_regular : ""}
                  onChange={(e) => setMensualRegular(+e.target.value)}
                  inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
               />
               {/* preio mensual */}
               <TextField
                  size="small"
                  label="MXN Renta/mes contrato anual"
                  id="outlined-size-small"
                  fullWidth
                  sx={{margin: "0px 2%"}}
                  value={mensual_anual ? mensual_anual : ""}
                  onChange={(e) => setMensualAnual(+e.target.value)}
                  inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
               />
            </DataRow>
            <DataRow>
               {/* capacity */}
               <TextField
                  size="small"
                  label="Capacidad máxima"
                  id="outlined-size-small"
                  disabled={isLand}
                  fullWidth
                  sx={{margin: "0px 2%"}}
                  value={capacity ? capacity : ""}
                  onChange={(e) => setCapacity(+e.target.value)}
                  inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
               />
               {/* Pet Friendly */}
               <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                  <InputLabel id="demo-controlled-open-select-label">Pet Friendly</InputLabel>
                  <Select
                     displayEmpty
                     labelId="demo-controlled-open-select-label"
                     id="demo-controlled-open-select"
                     label="Pet Friendly"
                     disabled={isLand}
                     value={petFriendly}
                     onChange={(e) => setPetFriendly(e.target.value)}
                  >
                     <MenuItem value={"true"}>Sí</MenuItem>
                     <MenuItem value={"false"}>No</MenuItem>
                  </Select>
               </FormControl>
            </DataRow>
            <h3 className="text-blue">Descripción de la propiedad</h3>
            {/* description2 */}
            <TextField
               size="small"
               label="Descripción breve (ficha técnica)"
               id="outlined-size-small"
               fullWidth
               multiline
               sx={{margin: "0px 2%"}}
               value={desc2 ? desc2 : ""}
               onChange={(e) => setDesc2(e.target.value)}
            />
            {/* description */}
            <TextField
               size="small"
               label="Descripción detallada"
               id="outlined-size-small"
               fullWidth
               multiline
               sx={{margin: "0px 2%"}}
               value={desc ? desc : ""}
               onChange={(e) => setDesc(e.target.value)}
            />
            <h3 className="text-blue">Notas de la propiedad</h3>
            <h3>Notas públicas</h3>
            {/* public notes */}
            <TextField
               size="small"
               label="Notas públicas"
               id="outlined-size-small"
               fullWidth
               multiline
               sx={{margin: "0px 2%"}}
               value={notes ? notes : ""}
               onChange={(e) => setNotes(e.target.value)}
            />
            <h3>Notas privadas</h3>
            {/* private notes */}
            <TextField
               size="small"
               label="Notas privadas"
               id="outlined-size-small"
               fullWidth
               multiline
               sx={{margin: "0px 2%"}}
               value={privateNotes ? privateNotes : ""}
               onChange={(e) => setPrivateNotes(e.target.value)}
            />
            <h3>Videos</h3>
            {/* video links */}
            <div className="w-full my-0 mx-[5%] flex-col space-x-2 ">
               <div className="w-full my-0 mx-[2%] flex space-x-2">
                  <TextField
                     size="small"
                     label="Links de videos"
                     id="outlined-size-small"
                     fullWidth
                     sx={{margin: "0px 2%"}}
                     value={videos ? videos : ""}
                     onChange={(e) => setVideos(e.target.value)}
                  />
                  <AddIcon className="text-blue-600 self-center" onClick={handleVideoChange} />
               </div>
               {videosList.length > 0 &&
                  videosList.map((video, index) => (
                     <div key={video} className="flex space-x-4 my-4 border rounded p-5">
                        <p className="w-[100%] overflow-x-scroll overflow-y-hidden">{video}</p>
                        <DeleteIcon
                           className="text-red-600 self-center"
                           onClick={() => handleVideoDelete(index)}
                        />
                     </div>
                  ))}
            </div>
            {/* add button */}
            {!loadingSubmit && (
               <>
                  <button
                     className="rounded bg-green p-2 mb-5 text-white font-bold w-[200px] self-center"
                     onClick={handleSubmit}
                  >
                     Guardar Cambios
                  </button>
                  <button
                     className="rounded bg-red p-2 mb-5 mt-10 text-white font-bold w-[200px] self-center"
                     onClick={() => setShowDeleteModal(true)}
                  >
                     Eliminar propiedad
                  </button>
               </>
            )}
            {loadingSubmit && (
               <button
                  className="rounded bg-blue p-2 mb-5 text-white font-bold w-[200px] self-center"
                  disabled
               >
                  Cargando...
               </button>
            )}
         </div>
         <DeletePropertyModal
            isOpen={showDeleteModal}
            setIsOpen={setShowDeleteModal}
            userEmail={user.email}
            propertyId={id}
         />
      </Page>
   ) : (
      <Page>
         <div className="flex flex-col items-center justify-center h-screen">
            <h1 className="text-2xl font-bold">Cargando...</h1>
         </div>
      </Page>
   )
}

export default EditInmueble
