/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useEffect} from "react"
// router dom
import {useNavigate} from "react-router-dom"
// get all condos
import {getAllConds} from "../../api/condominio"
// material ui components
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import VisibilityIcon from "@mui/icons-material/Visibility"
import {CSVLink} from "react-csv"
// components
import Page from "../../layouts/Page"
import {PROD_URL} from "../../constants"

function ExcelViewCond() {
   const [condos, setCondos] = useState<any[]>([])
   const [loading, setLoading] = useState<boolean>(true)
   const navigate = useNavigate()

   useEffect(() => {
      const getCondos = async () => {
         const condos = await getAllConds()
         setCondos(condos?.data?.data)
         setLoading(false)
      }
      getCondos()
   }, [])

   const headers = [
      {
         label: "Ver en página",
         key: "ver",
      },
      {
         label: "Nombre",
         key: "name",
      },
      {
         label: "Descripción",
         key: "desc",
      },
      {
         label: "ID del video",
         key: "videoId",
      },
      {
         label: "Dirección",
         key: "address",
      },
      {
         label: "Zona",
         key: "zone",
      },
      {
         label: "Clasificación",
         key: "class",
      },
      {
         label: "Acceso a la playa",
         key: "access_to_beach",
      },
      {
         label: "Club de playa",
         key: "beach_club",
      },
      {
         label: "Pet friendly",
         key: "pet_friendly",
      },
      {
         label: "Rentas vacacionales permitidas",
         key: "vacation_rentals_allowed",
      },
      {
         label: "Antigüedad",
         key: "antiquity",
      },
      {
         label: "Amenidades",
         key: "amenities",
      },
      {
         label: "Tamaño del condominio",
         key: "condo_size",
      },
      {label: "URL Maps Embed", key: "googleMaps"},
      {label: "URL Maps Compartir", key: "mapsShareUrl"},
   ]

   function parseDataForCSV(data: any[]) {
      return data.map((condo) => ({
         ...condo,
         amenities: condo.amenities?.join(", "),
         class: condo.class ? condo.class : "sin clasificar",
         ver: `${PROD_URL}/condominios/${condo.id}`,
      }))
   }

   if (loading) {
      return (
         <Page>
            <div className="font-bold text-xl w-full h-full text-center mt-10">Loading...</div>
         </Page>
      )
   }

   return (
      <Page>
         <ArrowBackIcon
            className="font-bold mb-5 cursor-pointer mt-5"
            onClick={() => navigate("/inmuebles")}
         />
         <CSVLink
            headers={headers}
            data={parseDataForCSV(condos)}
            className="flex text-green-600 font-bold underline"
            filename="condominios-cad.csv"
         >
            Descargar en formato CSV
         </CSVLink>
         <TableContainer component={Paper} sx={{width: "95%", height: "80%"}}>
            <Table stickyHeader aria-label="sticky table">
               <TableHead>
                  {headers.map((header) => (
                     <TableCell
                        key={header.key}
                        align="center"
                        className="min-w-[150px]"
                        style={{fontSize: "10px"}}
                     >
                        {header.label}
                     </TableCell>
                  ))}
               </TableHead>
               <TableBody>
                  {condos.map((condo) => (
                     <TableRow
                        key={condo.id}
                        sx={{"&:last-child td, &:last-child th": {border: 0}}}
                     >
                        <TableCell style={{fontSize: "10px"}} align="center">
                           <VisibilityIcon
                              className="cursor-pointer hover:text-blue-600"
                              onClick={() => navigate(`/condominios/${condo.id}`)}
                           />
                        </TableCell>
                        <TableCell style={{fontSize: "10px"}}>{condo.name}</TableCell>
                        <TableCell style={{fontSize: "10px"}}>
                           <div className="w-[200px] overflow-scroll h-[50px]">{condo.desc}</div>
                        </TableCell>
                        <TableCell style={{fontSize: "10px"}}>{condo.videoId}</TableCell>
                        <TableCell style={{fontSize: "10px"}}>{condo.address}</TableCell>
                        <TableCell style={{fontSize: "10px"}}>{condo.zone}</TableCell>
                        <TableCell style={{fontSize: "10px"}}>
                           {condo.class ? condo.class : "sin clasificar"}
                        </TableCell>
                        <TableCell style={{fontSize: "10px"}}>{condo.access_to_beach}</TableCell>
                        <TableCell style={{fontSize: "10px"}}>
                           {condo.beach_club ? "Tiene club de playa" : "Sin club de playa"}
                        </TableCell>
                        <TableCell style={{fontSize: "10px"}}>
                           {condo.pet_friendly ? "Es pet friendly" : "No es pet friendly"}
                        </TableCell>
                        <TableCell style={{fontSize: "10px"}}>
                           {condo.vacation_rentals_allowed
                              ? "Permite rentas vacacionales"
                              : "No permite rentas vacacionales"}
                        </TableCell>
                        <TableCell style={{fontSize: "10px"}}>{condo.antiquity}</TableCell>
                        <TableCell style={{fontSize: "10px"}}>
                           <div className="h-[50px] overflow-scroll" style={{fontSize: "10px"}}>
                              {condo.amenities?.map((amenity: string) => (
                                 <p key={amenity} className="mb-2">
                                    {amenity}
                                 </p>
                              ))}
                           </div>
                        </TableCell>
                        <TableCell style={{fontSize: "10px"}}>{condo.condo_size}</TableCell>
                        <TableCell style={{fontSize: "10px"}}>
                           <div className="w-[160px] overflow-x-scroll p-3">{condo.googleMaps}</div>
                        </TableCell>
                        <TableCell style={{fontSize: "10px"}}>
                           <div className="w-[160px] overflow-x-scroll p-3">
                              {condo.mapsShareUrl}
                           </div>
                        </TableCell>
                     </TableRow>
                  ))}
               </TableBody>
            </Table>
         </TableContainer>
      </Page>
   )
}

export default ExcelViewCond
