import React, {useState, useEffect} from "react"
import {Button} from "@mui/material"
import Alert from "@mui/material/Alert"
import DeleteIcon from "@mui/icons-material/Delete"
import AttachFileIcon from "@mui/icons-material/AttachFile"
import {ReactSortable} from "react-sortablejs"
import {nanoid} from "nanoid"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import {getImageUrl} from "@/api/images"
import SaveIcon from "@mui/icons-material/Save"
import {isMobile} from "@/utils"

interface SortableImageType {
   path?: string
   id: string
   databasePath?: string
   fileObject?: File
}

// types of the props
// addImages is in charge of setting the showImageform to false
// and adding the images to the previous state
interface Props {
   addImages: (images: any, imageCategory: string, deleteImagesEmpty?: any, newImages?: any) => void
   imageName: string
   images: any
   deleteImagesEmpty?: any
   newImages?: any
   closeImageForm?: () => void
}

function UploadImagesForm({
   addImages,
   imageName,
   images,
   deleteImagesEmpty,
   newImages,
   closeImageForm,
}: Props) {
   // state
   const [displayImages, setDisplayImages] = useState<Array<SortableImageType>>([])
   const [error, setError] = useState<null | string>(null)

   useEffect(() => {
      const fetchImages = async () => {
         if (images) {
            for (const path of images) {
               if (typeof path === "string") {
                  const imgUrl = await getImageUrl(path)
                  setDisplayImages((prev) => [
                     ...prev,
                     {path: imgUrl, id: nanoid(), databasePath: path},
                  ])
               } else {
                  setDisplayImages((prev) => [
                     ...prev,
                     {
                        fileObject: path,
                        id: nanoid(),
                        databasePath: URL.createObjectURL(path),
                     },
                  ])
               }
            }
         }
      }
      fetchImages()
   }, [images])

   // function for when the user selects an image
   // we set the selectedImages state to the ones selected by the user
   const imageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      setError(null)
      if (e.target.files) {
         for (const file of Array.from(e.target.files)) {
            if (
               !(
                  file.name.endsWith("jpg") ||
                  file.name.endsWith("png") ||
                  file.name.endsWith("jpeg") ||
                  file.name.endsWith("JPEG") ||
                  file.name.endsWith("JPG") ||
                  file.name.endsWith("PNG")
               )
            ) {
               setError("Solo se permiten imagenes jpg, jpeg o png")
               return
            }
         }
         const fileArray = Array.from(e.target.files).map((file) => ({
            fileObject: file,
            id: nanoid(),
            path: URL.createObjectURL(file),
         }))
         setDisplayImages([...displayImages, ...fileArray])
         Array.from(e.target.files).map((file: any) => URL.revokeObjectURL(file))
      }
   }

   // function for when the user clicks on the image
   const handleFileDelete = (index: number) => {
      const newDisplayImages = displayImages.filter((_image, i) => i !== index)
      setDisplayImages(newDisplayImages)
      if (deleteImagesEmpty) {
         if (displayImages[index].path) {
            if (deleteImagesEmpty[imageName] === undefined) {
               deleteImagesEmpty[imageName] = []
            } else {
               // si la imagen se elimina localmente solo entonces no se agregara a la lista de imagenes a eliminar
               if (displayImages[index].databasePath) {
                  deleteImagesEmpty[imageName].push(displayImages[index].databasePath)
               }
            }
         }
      }
   }

   // when the user clicks on uplaod images
   // this function is called
   const handleUpload = async () => {
      setError(null)
      if (displayImages.length === 0) {
         setError("No hay imagenes seleccionadas")
         return
      }
      if (newImages === undefined) {
         newImages = {}
      }
      newImages[imageName] = []
      displayImages.forEach((image) => {
         if (newImages[imageName] === undefined && image.fileObject) {
            newImages[imageName] = [image.fileObject]
            return
         }
         if (image.fileObject) {
            newImages[imageName].push(image.fileObject)
         }
         return
      })
      addImages(
         displayImages.map((image) => image.fileObject || image.databasePath),
         imageName,
         deleteImagesEmpty || null,
         newImages || null,
      )
   }

   return (
      <div className="w-full flex flex-col">
         {closeImageForm && (
            <ArrowBackIcon
               className="font-bold cursor-pointer mt-5 ml-5"
               onClick={() => closeImageForm()}
            />
         )}
         <h3 className="text-center text-2xl text-blue-600 font-bold mt-5 mb-5">
            Agregar imágenes de: {imageName}
         </h3>
         <div className="mb-4 mx-auto">
            <Button
               variant="outlined"
               startIcon={<AttachFileIcon />}
               onClick={() => {
                  const fileInput: any = document.querySelector("#file_input")
                  fileInput.click()
               }}
            >
               Selecciona imágenes
            </Button>
            <input
               id="file_input"
               required
               type="file"
               style={{display: "none"}}
               multiple
               onChange={imageHandler}
               accept=".jpg,.png,.jpeg"
               name={imageName}
            />
         </div>
         {error && (
            <Alert severity="error" className="text-center text-2xl mb-5 w-[30%] mx-auto">
               {error}
            </Alert>
         )}
         <ReactSortable
            list={displayImages}
            setList={setDisplayImages}
            className="max-h-full max-h-auto mt-[1rem] flex flex-col items-center md:flex-row md:flex-wrap justify-center gap-4 md:gap-8"
            delay={isMobile() ? 50 : 0}
         >
            {displayImages.map((image, i) => (
               <div
                  key={image.id}
                  className="flex flex-col items-center justify-center rounded-sm cursor-pointer w-[60%] md:w-60 gap-2"
               >
                  <div className="relative">
                     <img
                        src={image.path || image.databasePath}
                        alt=""
                        className="object-cover rounded-sm"
                     />
                     <DeleteIcon
                        className="absolute text-red cursor-pointer top-[80%] left-[80%]"
                        onClick={() => handleFileDelete(displayImages.indexOf(image))}
                     />
                  </div>
                  <p className="py-2 px-4 rounded border text-center max-w-min flex self-center">
                     {i + 1}
                  </p>
               </div>
            ))}
         </ReactSortable>
         <button
            className="my-5 rounded-lg bg-green px-2 py-1 text-white font-bold w-[200px] self-center mx-auto"
            onClick={handleUpload}
         >
            Subir y guardar <SaveIcon />
         </button>
         {/* <button
            onClick={closeImageForm}
            className="bg-gray-500 text-white px-2 py-1 rounded-lg w-[200px] self-center mx-auto"
         >
            Cancelar
         </button> */}
      </div>
   )
}

export default UploadImagesForm
