import React from "react"
import {Modal} from "@mui/material"
import {deleteProperty} from "../../api/inmueble"
import {useNavigate} from "react-router-dom"

interface Props {
   isOpen: boolean
   setIsOpen: (isOpen: boolean) => void
   userEmail: string
   propertyId: string
}

export default function DeletePropertyModal({isOpen, setIsOpen, propertyId: id}: Props) {
   const navigate = useNavigate()
   // texto que el usuario debe ingresar para poder eliminar la propiedad
   const [confirmText, setConfirmText] = React.useState("")
   // si el usuario ingresó el texto correcto
   const [shouldProceed, setShouldProceed] = React.useState(false)
   // error al eliminar la propiedad
   const [error, setError] = React.useState("")
   async function handleDelete() {
      // si el usuario no ingresó el texto correcto, no se puede eliminar la propiedad
      if (shouldProceed) {
         const res = await deleteProperty(id)
         // si hubo éxito, se cierra el modal y se redirige a la página de inmuebles
         if (res.status === 200) {
            setError("")
            closeModal()
            navigate("/inmuebles")
         } else {
            // si hubo error, se muestra el error
            setError(
               "Hubo un error al eliminar la propiedad. Intenta de nuevo. Recuerda que solo los administradores de la organización tienen permiso para eliminar propiedades.",
            )
         }
      } else {
         setError("")
         return
      }
   }
   function closeModal() {
      setIsOpen(false)
   }
   function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
      setError("")
      setConfirmText(e.target.value)
      if (e.target.value === "CONFIRMAR") {
         setShouldProceed(true)
      } else {
         setShouldProceed(false)
      }
   }
   return (
      <Modal open={isOpen}>
         <div className="flex flex-col justify-center items-center text-center p-4 w-[40%] bg-white rounded-lg ml-[35vw] my-[15%]">
            <h3 className="text-xl font-bold mb-4">Confirmación eliminar propiedad</h3>
            <p>
               ¿Estás seguro de que deseas eliminar esta propiedad? Escribe CONFIRMAR para poder
               hacerlo. Esta acción no es reversible.
            </p>
            {error && <p className="text-red-600">{error}</p>}
            <input
               type="text"
               onChange={handleChange}
               value={confirmText}
               placeholder="Ingresa confirmación aquí"
               className="p-2 mt-2 w-[60%]"
            />
            <button
               onClick={handleDelete}
               className={`text-white relative w-[50%] ${
                  shouldProceed ? "bg-red-600" : "bg-slate-400 cursor-not-allowed"
               } rounded p-[6px] block mx-auto mt-[20px]`}
            >
               ELIMINAR
            </button>
            <button
               onClick={closeModal}
               className="text-white relative w-[50%] bg-blue-600 rounded p-[6px] block mx-auto my-[20px]"
            >
               Cancelar
            </button>
         </div>
      </Modal>
   )
}
