import React from "react"
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom"
import {useAuthContext} from "@/hooks/useAuthContext"
import Login from "@/pages/auth/Login"
import Home from "@/pages/home/Home"
import Users from "@/pages/usuarios/Usuarios"
import Signup from "@/pages/auth/Signup"
import AddWorkerAdmin from "@/pages/usuarios/AddWorkerAdmin"
import CreateAccount from "@/pages/auth/CreateAccount"
import PropertiesPage from "@/pages/inmuebles/PropertiesPage"
import Condominios from "@/pages/condominios/Condominios"
import Profile from "@/pages/perfil/Profile"
import PropertyPage from "@/pages/inmuebles/PropertyPage"
import SingleCondominio from "@/pages/condominios/SingleCondominio"
import AddInmueble from "@/pages/inmuebles/AddInmueble"
import EditInmueble from "@/pages/inmuebles/EditInmueble"
import AddCondominio from "@/pages/condominios/AddCondominio"
import EditCondominio from "@/pages/condominios/EditCondominio"
import Temporadas from "@/pages/temporadas/Temporadas"
import EditPhotos from "@/pages/inmuebles/EditPhotos"
import EditCondoPhotos from "@/pages/condominios/EditCondoPhotos"
import Asociates from "@/pages/asociados/Associates"
import AddAssociate from "@/pages/asociados/AddAssociate"
import EditAssociate from "@/pages/asociados/EditAssociate"
import ExcelViewInm from "@/pages/inmuebles/ExcelViewInm"
import ExcelViewCond from "@/pages/condominios/ExcelViewCond"
import ResetPassword from "./pages/auth/ResetPassword"
import {clearCache} from "./utils"

function App() {
   const {user, authIsReady, isWorker} = useAuthContext()

   // Clear cache to get latest data
   React.useEffect(() => {
      clearCache()
   }, [])

   return (
      <div className="App font-sans">
         {authIsReady ? (
            <>
               <BrowserRouter>
                  <Routes>
                     <Route path="/" element={user ? <Home /> : <Navigate to="/login" />} />
                     <Route
                        path="/inmuebles"
                        element={user ? <PropertiesPage /> : <Navigate to="/login" />}
                     />
                     <Route
                        path="/inmuebles/:id"
                        element={user ? <PropertyPage /> : <Navigate to="/login" />}
                     />
                     <Route
                        path="/inmuebles/:id/renta"
                        element={user ? <PropertyPage /> : <Navigate to="/login" />}
                     />
                     <Route
                        path="/inmuebles/:id/venta"
                        element={user ? <PropertyPage /> : <Navigate to="/login" />}
                     />
                     <Route
                        path="/condominios/:id"
                        element={user ? <SingleCondominio /> : <Navigate to="/login" />}
                     />
                     <Route
                        path="/condominios"
                        element={user ? <Condominios /> : <Navigate to="/login" />}
                     />
                     <Route
                        path="/perfil"
                        element={user ? <Profile /> : <Navigate to="/login" />}
                     />
                     <Route path="/login" element={!user ? <Login /> : <Navigate to="/" />} />
                     <Route path="/signup" element={!user ? <Signup /> : <Navigate to="/" />} />
                     <Route
                        path="/crearCuenta"
                        element={isWorker && !user ? <CreateAccount /> : <Navigate to="/login" />}
                     />
                     <Route
                        path="reset-password"
                        element={!user ? <ResetPassword /> : <Navigate to="/" />}
                     />
                     <Route
                        path="/usuarios"
                        element={user ? <Users /> : <Navigate to="/login" />}
                     />
                     <Route
                        path="/usuarios/agregar"
                        element={
                           user ? (
                              user.admin ? (
                                 <AddWorkerAdmin />
                              ) : (
                                 <Admin403 />
                              )
                           ) : (
                              <Navigate to="/login" />
                           )
                        }
                     />
                     <Route
                        path="/inmuebles/nuevo"
                        element={
                           user ? (
                              user.admin ? (
                                 <AddInmueble />
                              ) : (
                                 <Admin403 />
                              )
                           ) : (
                              <Navigate to="/login" />
                           )
                        }
                     />
                     <Route
                        path="/inmuebles/editInmueble/:id"
                        element={
                           user ? (
                              user.admin ? (
                                 <EditInmueble />
                              ) : (
                                 <Admin403 />
                              )
                           ) : (
                              <Navigate to="/login" />
                           )
                        }
                     />
                     <Route
                        path="/inmuebles/editPhotos/:id"
                        element={
                           user ? (
                              user.admin ? (
                                 <EditPhotos />
                              ) : (
                                 <Admin403 />
                              )
                           ) : (
                              <Navigate to="/login" />
                           )
                        }
                     />
                     <Route
                        path="/condominios/nuevo"
                        element={
                           user ? (
                              user.admin ? (
                                 <AddCondominio />
                              ) : (
                                 <Admin403 />
                              )
                           ) : (
                              <Navigate to="/login" />
                           )
                        }
                     />
                     <Route
                        path="/condominios/editCondominio/:id"
                        element={
                           user ? (
                              user.admin ? (
                                 <EditCondominio />
                              ) : (
                                 <Admin403 />
                              )
                           ) : (
                              <Navigate to="/login" />
                           )
                        }
                     />
                     <Route
                        path="/condominios/editPhotos/:id"
                        element={
                           user ? (
                              user.admin ? (
                                 <EditCondoPhotos />
                              ) : (
                                 <Admin403 />
                              )
                           ) : (
                              <Navigate to="/login" />
                           )
                        }
                     />
                     <Route
                        path="/temporadas"
                        element={user ? <Temporadas /> : <Navigate to="/login" />}
                     />
                     <Route
                        path="/asociados"
                        element={
                           user ? (
                              user.admin ? (
                                 <Asociates />
                              ) : (
                                 <Admin403 />
                              )
                           ) : (
                              <Navigate to="/login" />
                           )
                        }
                     />
                     <Route
                        path="/asociados/add"
                        element={
                           user ? (
                              user.admin ? (
                                 <AddAssociate />
                              ) : (
                                 <Admin403 />
                              )
                           ) : (
                              <Navigate to="/login" />
                           )
                        }
                     />
                     <Route
                        path="/asociados/edit/:id"
                        element={
                           user ? (
                              user.admin ? (
                                 <EditAssociate />
                              ) : (
                                 <Admin403 />
                              )
                           ) : (
                              <Navigate to="/login" />
                           )
                        }
                     />
                     <Route
                        path="/inmuebles/excelView"
                        element={
                           user ? (
                              user.admin ? (
                                 <ExcelViewInm />
                              ) : (
                                 <Admin403 />
                              )
                           ) : (
                              <Navigate to="/login" />
                           )
                        }
                     />
                     <Route
                        path="/condominios/excelView"
                        element={
                           user ? (
                              user.admin ? (
                                 <ExcelViewCond />
                              ) : (
                                 <Admin403 />
                              )
                           ) : (
                              <Navigate to="/login" />
                           )
                        }
                     />
                  </Routes>
               </BrowserRouter>
            </>
         ) : (
            <FullScreenLoader />
         )}
      </div>
   )
}

export default App

// Full screen login component
import {useEffect, useState} from "react"
import Admin403 from "./pages/auth/Admin403"

function FullScreenLoader() {
   const [message, setMessage] = useState("Autenticando...")
   const [error, setError] = useState(false)

   useEffect(() => {
      const timer1 = setTimeout(() => {
         setMessage("El servidor se encontraba en estado de reposo, espere un momento...")
      }, 6000)

      const timer2 = setTimeout(() => {
         setMessage("El servidor está tardando más de lo esperado, espere un momento...")
      }, 13000)

      const timer3 = setTimeout(() => {
         setMessage(
            "Ocurrió un error inesperado, reinicie la página o revise los logs de la consola para mayor información. Si el error persiste, contacte a soporte técnico o al administrador.",
         )
         setError(true)
      }, 25000)

      return () => {
         clearTimeout(timer1)
         clearTimeout(timer2)
         clearTimeout(timer3)
      }
   }, [])
   return (
      <div className="h-[100vh] flex flex-col justify-center items-center bg-blue text-white gap-8">
         <img
            src="/logos/white.png"
            alt="Casas Acapulco Diamante Logo"
            width={200}
            height={200}
            className={!error ? "animate-pulse" : ""}
         />
         <p className={`text-lg md:mx-[30%] mx-4 text-center ${!error && "animate-pulse"}`}>
            {message}
         </p>
      </div>
   )
}
