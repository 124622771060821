import React, {useState, useEffect} from "react"
import {useLocation, useNavigate} from "react-router-dom"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import CondominioInfo from "@/components/condos/CondoInfo"
import {inmAndCondIndStyles as styles} from "@/constants"
import {getCondominioById} from "@/api/condominio"
import Page from "@/layouts/Page"

function SingleCondominio() {
   // get values sent from the useNavigate()
   const location = useLocation()
   const navigate = useNavigate()
   // get the id of the condominio
   const pathName = location.pathname.split("/condominios/")[1]
   // state
   const [condominio, setCondominio] = useState<any>()

   useEffect(() => {
      window.scrollTo(0, 0)
      const state: any = location.state
      const getCond = async () => {
         try {
            // if the prop is not defined, then fetch the condominio again
            let resCondo
            if (!state.condo) {
               resCondo = await getCondominioById(pathName)
               setCondominio(resCondo.data.data)
            }
            // if the prop is defined, then just set the condominio to that value
            if (state.condo) {
               setCondominio(state.condo)
            }
         } catch (error) {
            console.log(error)
         }
      }
      getCond()
   }, [])

   return (
      <Page className="max-w-[1600px] mx-auto">
         <ArrowBackIcon
            className="font-bold mb-7 cursor-pointer mt-5"
            onClick={() => navigate(-1)}
         />
         <CondominioInfo condId={pathName} styles={styles} condoData={condominio} />
      </Page>
   )
}

export default SingleCondominio
