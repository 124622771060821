// Page for auth components. They even share their inner container, only thing
// that changes is content
// Rodrigo Nunez, May 2024
import React from "react"
import {Footer} from "./Page"

export default function AuthPage({children, title}: {children: React.ReactNode; title: string}) {
   return (
      <main className="flex flex-col items-center gap-8 justify-between min-h-screen text-gray-500">
         <header className="flex flex-col md:flex-row gap-4 items-center mt-20">
            <img
               src="/logos/colored_house.png"
               alt="Logo de Casas Acapulco Diamante"
               height={50}
               width={80}
            />
            <h1 className="flex flex-wrap items-center justify-center font-bold text-3xl md:text-5xl drop-shadow-lg md:shadowed-text-black font-peignot mx-8 md:mx-0">
               <p className="text-red">Casas</p> &nbsp;<p className="text-green">Acapulco</p>
               &nbsp;
               <p className="text-blue">Diamante</p> &nbsp;
               <p className="text-gray-500">Socios</p>
            </h1>
         </header>
         <section className="bg-gray-100 border border-gray-300 shadow-lg flex flex-col items-center p-10 w-[90vw] md:w-[50vw] md:max-w-[800px] rounded-md text-center gap-4 mb-20">
            <h2 className="text-2xl text-blue font-bold">{title}</h2>
            {children}
         </section>
         <Footer />
      </main>
   )
}

import {Link} from "react-router-dom"
export function GoToLogin() {
   return (
      <div className="mt-[20px]">
         <p className="text-gray-400 italic">¿Ya tienes una cuenta?</p>
         <Link
            to="/login"
            className="bg-green shadow-md hover:bg-gray-800 text-gray rounded-lg p-[6px] block mx-auto text-white mt-4 px-4"
         >
            Iniciar sesión
         </Link>
      </div>
   )
}
