import React, {useState} from "react"
import {useNavigate} from "react-router-dom"
import {Alert} from "@mui/material"
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail"
import {useAuthContext} from "@/hooks/useAuthContext"
import {CheckSolicitud} from "@/api/solicitud"
import AuthPage, {GoToLogin} from "@/layouts/AuthPage"
import Checkbox from "@mui/material/Checkbox"
import TextInput from "@/components/ui/TextInput"

function Signup() {
   const [userError, setUserError] = useState<null | string>(null)
   const [privateKey, setPrivateKey] = useState("")
   const [keyVisible, setKeyVisible] = useState(false)
   const [email, setEmail] = useState("")
   const [isPending, setIsPending] = useState(false)
   const {dispatch} = useAuthContext()
   const navigate = useNavigate()

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      setIsPending(true)
      setUserError(null)
      // VERIFY PRIVATE KEY
      try {
         // VERIFY PRIVATE KEY
         const solicitud = await CheckSolicitud(email, privateKey)
         if (solicitud.data.solicitud.key !== privateKey) {
            setIsPending(false)
            setUserError("Clave privada incorrecta")
            return
         }
         await dispatch({type: "IS_WORKER"})
         setIsPending(false)
         navigate("/crearCuenta", {state: {email, key: privateKey}})
      } catch (error) {
         setIsPending(false)
         setUserError("No existe una solicitud con ese correo electronico")
      }
   }

   return (
      <AuthPage title="Registra nuevo usuario">
         <p>
            Inserta tu correo electrónico y la llave que te compartió el administrador de Casas
            Acapulco Diamante.
         </p>
         {userError && (
            <Alert className="mb-7" severity="error">
               {userError}
            </Alert>
         )}
         <form action="submit" className="flex flex-col items-center gap-4" onSubmit={handleSubmit}>
            <TextInput
               icon={<AlternateEmailIcon />}
               placeholder="Correo electrónico *"
               type="email"
               value={email}
               onChange={(e) => setEmail(e.target.value)}
               required
            />
            <TextInput
               icon="🔑"
               placeholder="Llave de acceso *"
               type={keyVisible ? "text" : "password"}
               value={privateKey}
               onChange={(e) => setPrivateKey(e.target.value)}
               required
            />
            <div className="flex items-center">
               <Checkbox
                  checked={keyVisible}
                  onChange={() => setKeyVisible(!keyVisible)}
                  color="secondary"
               />
               <p>Mostrar llave de acceso</p>
            </div>
            {!isPending && (
               <button
                  className="border rounded-xl bg-green hover:bg-gray-500 py-1 px-4 text-white"
                  type="submit"
               >
                  Registrarme
               </button>
            )}
            {isPending && (
               <button
                  disabled
                  className="border rounded-xl bg-blue py-1 px-4 text-white animate-pulse"
               >
                  Cargando...
               </button>
            )}
         </form>
         <GoToLogin />
      </AuthPage>
   )
}
export default Signup
