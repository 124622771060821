import React from "react"
import Bed from "@mui/icons-material/Bed"
import Group from "@mui/icons-material/Group"
import Shower from "@mui/icons-material/Shower"
import SquareFoot from "@mui/icons-material/SquareFoot"
import Home from "@mui/icons-material/Home"
import TimeToLeave from "@mui/icons-material/TimeToLeave"
import Elevator from "@mui/icons-material/Elevator"

export const PROPERTY_CHARACTERISTICS = [
   {
      title: "Habitaciones",
      icon: <Bed className="text-green" />,
      key: "rooms",
      suffix: "rec.",
   },
   {
      title: "Capacidad",
      icon: <Group className="text-green" />,
      key: "capacity",
      suffix: "personas",
   },
   {
      title: "Baños",
      icon: <Shower className="text-green" />,
      key: "bathrooms",
      suffix: "baños",
   },
   {
      title: "Mts²",
      icon: <SquareFoot className="text-green" />,
      key: "mts",
      suffix: "m²",
   },
   {
      title: "Mts² Construcción",
      icon: <Home className="text-green" />,
      key: "mtsConstruction",
      suffix: "m²",
   },
   {
      title: "Estacionamientos",
      icon: <TimeToLeave className="text-green" />,
      key: "parkingLots",
      suffix: "cajones",
   },
   {
      title: "Piso",
      icon: <Elevator className="text-green" />,
      key: "floor",
      suffix: "nivel",
   },
]

export const PROPERTY_SECTIONS = [
   {title: "Información", id: "property-info"},
   {title: "Notas", id: "property-notes"},
   {title: "Archivos", id: "property-files"},
   {title: "Calendario", id: "property-calendar"},
   {title: "Videos", id: "property-videos"},
   {title: "Ubicación", id: "property-location"},
   {title: "Imágenes", id: "property-images"},
]
