import React, {useState, useEffect} from "react"
import {Status} from "../../constants"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import VerifiedSharpIcon from "@mui/icons-material/VerifiedSharp"
import ConstructionIcon from "@mui/icons-material/Construction"
import StormIcon from "@mui/icons-material/Storm"
import GroupsIcon from "@mui/icons-material/Groups"
import EngineeringIcon from "@mui/icons-material/Engineering"

export default function StatusTile({status, className = ""}: {status: Status; className?: string}) {
   // Determine the data based on the status prop
   const data = (() => {
      switch (status) {
         case Status.VERIFIED:
            return {
               color: "text-blue",
               icon: <VerifiedSharpIcon />,
               text: status,
            }
         case Status.RESTORING:
            return {
               color: "text-green",
               icon: <EngineeringIcon />,
               text: status,
            }
         case Status.PRESALE:
            return {
               color: "text-pink",
               icon: <AccessTimeIcon />,
               text: status,
            }
         case Status.FRACTIONAL:
            return {
               color: "text-red",
               icon: <GroupsIcon />,
               text: status,
            }
         case Status.TO_RESTORE:
            return {
               color: "text-orange-500",
               icon: <ConstructionIcon />,
               text: status,
            }
         default:
            return {
               color: "text-gray-500",
               icon: <StormIcon />,
               text: status,
            }
      }
   })()

   return (
      <p className={`flex gap-1 text-sm font-bold ${className} ${data.color} items-center`}>
         <span>{data.text}</span>
         {data.icon}
      </p>
   )
}
