import {API_URL} from "../constants"

export async function getImageUrl(path: string) {
   const res = await fetch(`${API_URL}/images?path=${path}`)
   const {data} = await res.json()
   return data
}

export async function getImages(id: string, source: string) {
   const path = source === "property" ? "inmuebles" : "condominios"
   const response = await fetch(`${API_URL}/${path}/${id}/images`).then((res) => res.json())
   return response.data
}
