/* eslint-disable @typescript-eslint/no-explicit-any */
import {createContext, useEffect, useReducer} from "react"
import {API_URL} from "../constants"

// Types
interface base {
   user: {email: string; admin: boolean; worker: boolean} | null
   authIsReady: boolean
   isWorker: boolean
}

interface Props {
   children: JSX.Element
}

interface Action {
   type: string
   payload: any
}

// initial state for the useReducer hook
const initialState: base = {
   user: null,
   authIsReady: false,
   isWorker: false,
}

// create the AuthContext
export const AuthContext = createContext({} as any)

// create the reducer that the useReducer hook will take
export const AuthReducer = (state: base, action: Action) => {
   switch (action.type) {
      case "LOGIN":
         return {...state, user: action.payload, isWorker: true}
      case "LOGOUT":
         return {...state, user: action.payload, isWorker: false}
      case "IS_AUTH":
         return {...state, user: action.payload, authIsReady: true}
      case "IS_WORKER":
         return {...state, isWorker: true}
      default:
         return {...state}
   }
}

// create the component in which we will wrap all of our app
// to provide the auth context
export const AuthContextProvider = ({children}: Props) => {
   const [state, dispatch] = useReducer(AuthReducer, initialState)

   useEffect(() => {
      async function checkSession() {
         const response = await fetch(`${API_URL}/usuarios/session`, {
            method: "GET",
            mode: "cors",
            headers: {
               "Content-Type": "application/json",
               Origin: window.location.origin,
            },
            credentials: "include",
         })
         if (response.status === 200) {
            const user = await response.json()
            if (user.data.admin || user.data.worker) dispatch({type: "IS_AUTH", payload: user.data})
            else dispatch({type: "IS_AUTH", payload: null})
         } else {
            dispatch({type: "IS_AUTH", payload: null})
         }
      }
      checkSession()
   }, [dispatch])

   return (
      // eslint-disable-next-line react/react-in-jsx-scope
      <AuthContext.Provider value={{...state, dispatch}}>{children}</AuthContext.Provider>
   )
}
