import React from "react"

type PageSectionProps = {
   id: string
   title: string
   className?: string
   children: React.ReactNode
}

/**
 * PageSection component, made for property page and condo page sections originally
 * @param id - id of the section, for anchor links
 * @param className - extra classes for the section
 * @param children - children elements of the section
 * @param title - title of the section
 * @returns - section element with children
 */
const PageSection = ({children, id, className, title}: PageSectionProps) => {
   return (
      <section id={id}>
         <h2 className="bg-gray-200 w-full pl-4 py-2 shadow-xl">{title}</h2>
         <div className={`p-4 md:px-12 ${className}`}>{children}</div>
      </section>
   )
}

export default PageSection
