import React from "react"

type EditButtonProps = {
   label: string
   onClick: () => void
   icon: React.ReactNode
}

/**
 * Edit button component, primarily used for edit buttons in the properties or condos page
 * @param label - The label of the button
 * @param onClick - The function to call when the button is clicked
 * @param icon - The icon to display on the button
 * @returns The edit button component
 */
export default function EditButton({onClick, label, icon}: EditButtonProps) {
   return (
      <button
         onClick={onClick}
         className="px-2 md:px-8 py-1 border-2 border-green text-green rounded hover:border-blue hover:bg-blue hover:text-white flex gap-2 items-center truncate"
      >
         {label} {icon}
      </button>
   )
}
