import React, {useState} from "react"
import {Alert} from "@mui/material"
import InputLabel from "@mui/material/InputLabel"
import NativeSelect from "@mui/material/NativeSelect"
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail"
import {AddTrabajador} from "@/api/solicitud"
import Page from "@/layouts/Page"
import {Modal} from "@mui/material"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import {Link} from "react-router-dom"
import copy from "copy-to-clipboard"
import DoneIcon from "@mui/icons-material/Done"
import CloseIcon from "@mui/icons-material/Close"
import TextInput from "@/components/ui/TextInput"

function AddWorkerAdmin() {
   const [email, setEmail] = useState("")
   const [type, setType] = useState("Trabajador")
   const [privateKey, setPrivateKey] = useState<string | null>(null)
   const [error, setError] = useState<string | null>(null)
   const [copied, setCopied] = useState(false)

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      // post the email and type, create the uuid in node
      const solicitud = {email: email, type: type}
      // add worker
      const newUid = await AddTrabajador(solicitud, setError)
      // set the private key
      setPrivateKey(newUid)
   }

   const copyKey = () => {
      if (privateKey) {
         copy(privateKey)
         setCopied(true)
      }
      setTimeout(() => setCopied(false), 3000)
   }

   return (
      <Page className="flex flex-col items-center gap-8">
         <h3 className="text-3xl font-bold text-blue mt-20 text-center">Nuevo usuario</h3>
         <form
            className="bg-gray-100 border border-gray-300 shadow-lg flex flex-col items-center p-10 w-[90vw] md:w-[60vw] md:max-w-[800px] rounded-md text-center gap-4 mb-20"
            onSubmit={handleSubmit}
            action="submit"
         >
            <p>Ingresa el correo del nuevo usuario para generar una nueva llave de acceso.</p>
            {error && (
               <Alert className="mb-7" severity="error">
                  {error}
               </Alert>
            )}
            <TextInput
               icon={<AlternateEmailIcon />}
               placeholder="Correo electrónico *"
               type="email"
               value={email}
               onChange={(e) => setEmail(e.target.value)}
               required
            />
            <div className="mx-auto w-full md:w-[80%] h-full mb-4">
               <InputLabel variant="standard" className="text-start mb-2">
                  Tipo
               </InputLabel>
               <NativeSelect
                  className="w-full p-2"
                  defaultValue={"Trabajador"}
                  inputProps={{
                     name: "trabajador",
                     id: "uncontrolled-native",
                  }}
                  onChange={(e) => setType(e.target.value)}
               >
                  <option value={"Trabajador"}>Usuario</option>
                  <option value={"Administrador"}>Administrador</option>
               </NativeSelect>
            </div>
            <button
               className="border rounded-md md:rounded-full bg-green hover:bg-gray-800 px-6 py-2 text-white"
               type="submit"
            >
               Generar llave de acceso 🔑
            </button>
         </form>
         <Modal open={privateKey !== null} onClose={() => setPrivateKey(null)}>
            <div className="relative w-[90vw] md:w-[50vw] md:max-w-[600px] bg-white text-center p-10 my-[30%] md:my-[20%] text-gray-500 rounded-lg flex flex-col items-center gap-4 mx-auto">
               <button className="absolute right-6 top-6" onClick={() => setPrivateKey(null)}>
                  <CloseIcon fontSize="large" />
               </button>
               <h3 className="font-bold text-blue text-2xl">¡Felicidades!</h3>
               <p>
                  Has dado de alta un nuevo usuario en la plataforma de Casas Acapulco Diamante.
                  Comparte la llave de acceso al nuevo usuario para ingresar a la página iniciando
                  sesión. Copia la llave de acceso, no podrás verla de nuevo.
               </p>
               <h3 className="text-2xl text-green font-bold">Llave de Acceso</h3>
               <div className="flex items-center gap-2">
                  <p className="text-xl font-bold">{privateKey}</p>
                  {copied ? (
                     <div>
                        <p className="text-xs">Copiada.</p>
                        <DoneIcon fontSize="large" />
                     </div>
                  ) : (
                     <button onClick={copyKey}>
                        <ContentCopyIcon fontSize="large" />
                     </button>
                  )}
               </div>
               <Link
                  to="/usuarios"
                  className="border rounded-md bg-green text-white px-6 py-1 font-bold shadow-lg hover:bg-white hover:border hover:border-gray-500 hover:text-gray-500"
               >
                  Volver a usuarios
               </Link>
            </div>
         </Modal>
      </Page>
   )
}

export default AddWorkerAdmin
