import React, {useEffect} from "react"
import Page from "@/layouts/Page"

/**
 * Admin403 page, this page is shown when someone who is not an admin tries to access an admin route. And it redirects to home.
 */
export default function Admin403() {
   // Redirect to home after 5 seconds
   useEffect(() => {
      const timeout = setTimeout(() => {
         window.location.href = "/"
      }, 5000)
      return () => clearTimeout(timeout)
   }, [])

   return (
      <Page className="flex flex-col items-center justify-center gap-4 p-4">
         <h1 className="text-2xl md:text-3xl font-bold text-blue text-center animate-pulse">
            403 - Acceso denegado
         </h1>
         <p className="text-center md:mx-[20%]">
            Debes ser un administrador de la organización para acceder a este recurso. Serás
            redirigido a la página de inicio en unos segundos.
         </p>
      </Page>
   )
}
